export const toCamelCase = (str) => {
  return str
    ? str
        .toLowerCase()
        .replace(/[^a-zA-Z0-9,.+]+/g, ' ')
        .replace(/\b\w/g, (chr) => chr.toUpperCase())
        .trim()
    : str;
};

export const formatDimensions = (dimensions) => {
  if (!dimensions) return dimensions;
  const [depth, width, height] = dimensions.match(/\d+(\.\d+)?/g);
  return `Width: ${width}'' Height: ${height}'' Depth: ${depth}''`;
};
