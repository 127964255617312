import React, { useEffect } from "react";
import { toCamelCase } from "../../../utils/string";
import ReactGA from "react-ga4";

const ProductModal = ({ isOpen, onClose, product, reviews }) => {
  // Close modal on escape key press
  useEffect(() => {
    const handleEsc = (e) => {
      if (e.key === "Escape") onClose();
    };
    window.addEventListener("keydown", handleEsc);
    return () => window.removeEventListener("keydown", handleEsc);
  }, [onClose]);

  if (!isOpen) return null;

  // Prevent click propagation from content to overlay
  const handleContentClick = (e) => {
    e.stopPropagation();
  };

  const renderStars = (rating) => {
    return Array.from({ length: rating }).map((_, index) => (
      <svg
        key={index}
        className={`w-4 h-4 ${
          index < rating
            ? "text-yellow-400 fill-yellow-400"
            : "text-white fill-white"
        }`}
        viewBox="0 0 24 24"
      >
        <path d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z" />
      </svg>
    ));
  };

  const specs = [
    { label: "Product Dimensions", value: product?.product_dimension },
    // { label: "Recommended Usage", value: product?.recommended_usage },
    {
      label: "Capacity",
      value: product?.capacity ? `${product.capacity}ml` : null,
    },
    { label: "Weight", value: product?.weight ? `${product.weight}kg` : null },
    {
      label: "Motor Power",
      value: product?.motor_watt ? `${product.motor_watt}W` : null,
    },
  ].filter((spec) => spec.value);

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-6 mt-8"
      onClick={onClose}
    >
      <div
        className="bg-white rounded-xl max-w-2xl w-full max-h-[80vh] overflow-y-auto"
        onClick={handleContentClick}
      >
        {/* Header */}
        <div className="sticky top-0 bg-white border-b p-4 flex justify-between items-center">
          <h2 className="font-bold text-center md:text-start">{toCamelCase(product?.name)}</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 p-4"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        <div className="p-4">
          {/* Specifications */}
          <div className="mb-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {specs.map((spec, index) => (
                <div key={index} className="flex flex-col">
                  <span className="text-sm text-gray-600">{spec.label}</span>
                  <span className="font-medium">{spec.value}</span>
                </div>
              ))}
            </div>
          </div>

          {/* Reviews */}
          <div>
            <div className="text-center md:text-start mb-4">
              <h3 className="font-semibold">Customer Reviews from Amazon</h3>
              <a
                href={product?.url}
                onClick={() => {
                  ReactGA.event({
                    category: "User Interaction",
                    component: "Product Details",
                    action: "button_click",
                    label: "View on Amazon",
                    params: {
                      asin: product.amazon_asin,
                    },
                  });
                }}
                target="_blank"
                rel="noopener noreferrer"
                className="text-green-600 hover:text-green-700 flex items-center gap-2 justify-center md:justify-start"
              >
                View on Amazon
                <svg
                  className="w-4 h-4"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                  />
                </svg>
              </a>
            </div>

            <div className="space-y-4">
              {reviews
                ?.sort((a, b) => b.rating - a.rating)
                .map((review) => (
                  <div key={review.id} className="border rounded-lg p-4">
                    <div className="flex items-center justify-between mb-2">
                      <h4 className="text-start font-semibold">{review.title}</h4>
                      <div className="flex">{renderStars(review.rating)}</div>
                    </div>
                    <p className="text-sm text-start text-gray-600">
                      {review.body}
                    </p>
                    {review.verifiedPurchase && (
                      <p className="mt-2 text-start text-xs text-green-600 font-medium">
                        Verified Purchase
                      </p>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductModal;
