import React, { useState, useEffect } from "react";
import { SlidersHorizontal, ChevronDown, X, ChevronRight } from "lucide-react";

const FilterPanel = ({
  filters,
  setFilters,
  sortOption,
  setSortOption,
  onReset,
  defaultFilters,
  availableBrands,
}) => {
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [expandedSections, setExpandedSections] = useState(["categories"]); // Default expand categories

  const dropdownOptions = {
    price: [
      { label: "Under $50", min: 0, max: 50 },
      { label: "$50 - $100", min: 50, max: 100 },
      { label: "$100 - $200", min: 100, max: 200 },
      { label: "Over $200", min: 200, max: Infinity },
    ],
    sort: [
      { label: "$ Low to High", value: "price-asc" },
      { label: "$ High to Low", value: "price-desc" },
      // { label: "Top Rated", value: "rating-desc" },
      { label: "Largest", value: "capacity-desc" },
    ],
    categories: [
      { id: "portable", label: "Portable" },
      { id: "immersion", label: "Immersion" },
      { id: "kitchen", label: "Kitchen" },
      { id: "personal", label: "Personal" },
      { id: "travel", label: "Travel" },
      { id: "retro", label: "Retro" },
      { id: "hand", label: "Hand" },
      { id: "smoothie", label: "Smoothie" },
      { id: "baby food", label: "Baby Food" },
      { id: "pureeing", label: "Pureeing" },
      { id: "mixing", label: "Mixing" },
      { id: "frozen", label: "Frozen" },
      { id: "milk maker", label: "Milk Maker" },
      { id: "ice crush", label: "Ice Crush" },
      { id: "soups", label: "Soups" },
      { id: "protein shake", label: "Protein Shakes" },
      { id: "milkshake", label: "Milkshake" },
    ],
  };

  const toggleDropdown = (dropdown) => {
    setActiveDropdown(activeDropdown === dropdown ? null : dropdown);
  };

  const toggleSection = (section) => {
    setExpandedSections((prev) =>
      prev.includes(section)
        ? prev.filter((s) => s !== section)
        : [...prev, section]
    );
  };

  const getActiveFiltersCount = () => {
    let count = 0;
    if (filters.price.length) count += filters.price.length;
    if (filters.categories?.length) count += filters.categories.length;
    if (filters.brands?.length) count += filters.brands.length;
    if (filters.isBPAFree) count++;
    if (filters.isPVCFree) count++;
    if (filters.isPFOAFree) count++;
    if (filters.isLeadFree) count++;
    if (filters.isPTFEFree) count++;
    if (filters.isPhthalateFree) count++;
    return count;
  };

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    // onFilterApply();
  };

  const handleSortChange = (newSort) => {
    setSortOption(newSort);
    // onFilterApply();
  };

  const renderCategories = () => (
    <div className="space-y-2">
      {dropdownOptions.categories.map((category) => (
        <div key={category.id} className="space-y-1">
          <label className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={
                category.subcategories
                  ? category.subcategories.every((sub) =>
                      filters.categories?.includes(sub.id)
                    )
                  : filters.categories?.includes(category.id)
              }
              onChange={() => {
                const newCategories = [...(filters.categories || [])];
                if (category.subcategories) {
                  const allIncluded = category.subcategories.every((sub) =>
                    newCategories.includes(sub.id)
                  );
                  if (allIncluded) {
                    category.subcategories.forEach((sub) => {
                      const index = newCategories.indexOf(sub.id);
                      if (index !== -1) newCategories.splice(index, 1);
                    });
                  } else {
                    category.subcategories.forEach((sub) => {
                      if (!newCategories.includes(sub.id)) {
                        newCategories.push(sub.id);
                      }
                    });
                  }
                } else {
                  const index = newCategories.indexOf(category.id);
                  if (index === -1) {
                    newCategories.push(category.id);
                  } else {
                    newCategories.splice(index, 1);
                  }
                }
                handleFilterChange({ ...filters, categories: newCategories });
              }}
              className="rounded border-gray-300 text-green-600 focus:ring-green-500"
            />
            <span className="text-sm text-gray-600">{category.label}</span>
            {category.subcategories && (
              <button
                onClick={() => toggleSection(`category-${category.id}`)}
                className="ml-auto p-1 hover:bg-gray-100 rounded"
              >
                <ChevronRight
                  size={16}
                  className={`transform transition-transform ${
                    expandedSections.includes(`category-${category.id}`)
                      ? "rotate-90"
                      : ""
                  }`}
                />
              </button>
            )}
          </label>

          {category.subcategories &&
            expandedSections.includes(`category-${category.id}`) && (
              <div className="ml-6 space-y-1">
                {category.subcategories.map((sub) => (
                  <label key={sub.id} className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      checked={filters.categories?.includes(sub.id)}
                      onChange={() => {
                        const newCategories = [...(filters.categories || [])];
                        const index = newCategories.indexOf(sub.id);
                        if (index === -1) {
                          newCategories.push(sub.id);
                        } else {
                          newCategories.splice(index, 1);
                        }
                        handleFilterChange({
                          ...filters,
                          categories: newCategories,
                        });
                      }}
                      className="rounded border-gray-300 text-green-600 focus:ring-green-500"
                    />
                    <span className="text-sm text-gray-600">{sub.label}</span>
                  </label>
                ))}
              </div>
            )}
        </div>
      ))}
    </div>
  );

  const renderBrands = () => (
    <div className="space-y-2 max-h-48 overflow-y-auto pr-2">
      {availableBrands.map((brand) => (
        <label key={brand} className="flex items-center gap-2">
          <input
            type="checkbox"
            checked={filters.brands?.includes(brand)}
            onChange={() => {
              const newBrands = [...(filters.brands || [])];
              const index = newBrands.indexOf(brand);
              if (index === -1) {
                newBrands.push(brand);
              } else {
                newBrands.splice(index, 1);
              }
              handleFilterChange({ ...filters, brands: newBrands });
            }}
            className="rounded border-gray-300 text-green-600 focus:ring-green-500"
          />
          <span className="text-sm text-gray-600">{brand}</span>
        </label>
      ))}
    </div>
  );

  return (
    <div className="bg-white rounded-xl shadow-sm p-3 mb-6">
      <div className="flex items-center gap-2 flex-wrap">
        {/* Filter Button */}
        <div className="relative">
          <button
            onClick={() => toggleDropdown("filters")}
            className={`flex items-center gap-2 px-3 py-2 rounded-lg text-sm font-medium transition-all ${
              activeDropdown === "filters" || getActiveFiltersCount() > 0
                ? "bg-green-50 text-green-600"
                : "hover:bg-gray-50 text-gray-700"
            }`}
          >
            <SlidersHorizontal size={16} />
            Filters
            {getActiveFiltersCount() > 0 && (
              <span className="bg-green-100 text-green-600 rounded-full px-2 text-xs">
                {getActiveFiltersCount()}
              </span>
            )}
          </button>

          {activeDropdown === "filters" && (
            <div className="absolute top-full left-0 mt-2 w-64 bg-white rounded-lg shadow-lg p-4 z-50">
              <div className="space-y-4">
                <div className="flex items-center justify-between border-b pb-2">
                  <span className="font-medium">Filters</span>
                  {getActiveFiltersCount() > 0 && (
                    <button
                      onClick={onReset}
                      className="text-xs text-gray-500 hover:text-green-600"
                    >
                      Reset all
                    </button>
                  )}
                </div>

                {/* Categories Section */}
                <div className="space-y-2">
                  <div className="flex items-center justify-between">
                    <div className="font-medium text-sm text-gray-700">
                      Categories
                    </div>
                    <button
                      onClick={() => toggleSection("categories")}
                      className="p-1 hover:bg-gray-100 rounded"
                    >
                      <ChevronRight
                        size={16}
                        className={`transform transition-transform ${
                          expandedSections.includes("categories")
                            ? "rotate-90"
                            : ""
                        }`}
                      />
                    </button>
                  </div>
                  {expandedSections.includes("categories") &&
                    renderCategories()}
                </div>

                {/* Brands Section */}
                <div className="space-y-2">
                  <div className="flex items-center justify-between">
                    <div className="font-medium text-sm text-gray-700">
                      Brands
                    </div>
                    <button
                      onClick={() => toggleSection("brands")}
                      className="p-1 hover:bg-gray-100 rounded"
                    >
                      <ChevronRight
                        size={16}
                        className={`transform transition-transform ${
                          expandedSections.includes("brands") ? "rotate-90" : ""
                        }`}
                      />
                    </button>
                  </div>
                  {expandedSections.includes("brands") && renderBrands()}
                </div>

                {/* Features Section */}
                <div className="border-t pt-2">
                  <div className="font-medium text-sm text-gray-700 mb-2">
                    Features
                  </div>
                  <div className="space-y-2">
                    <label className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        checked={filters.isBPAFree}
                        onChange={(e) =>
                          handleFilterChange({
                            ...filters,
                            isBPAFree: e.target.checked,
                          })
                        }
                        className="rounded border-gray-300 text-green-600 focus:ring-green-500"
                      />
                      <span className="text-sm text-gray-600">BPA Free</span>
                    </label>

                    <label className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        checked={filters.isPVCFree}
                        onChange={(e) =>
                          handleFilterChange({
                            ...filters,
                            isPVCFree: e.target.checked,
                          })
                        }
                        className="rounded border-gray-300 text-green-600 focus:ring-green-500"
                      />
                      <span className="text-sm text-gray-600">PVC Free</span>
                    </label>

                    <label className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        checked={filters.isPFOAFree}
                        onChange={(e) =>
                          handleFilterChange({
                            ...filters,
                            isPFOAFree: e.target.checked,
                          })
                        }
                        className="rounded border-gray-300 text-green-600 focus:ring-green-500"
                      />
                      <span className="text-sm text-gray-600">PFOA Free</span>
                    </label>

                    <label className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        checked={filters.isLeadFree}
                        onChange={(e) =>
                          handleFilterChange({
                            ...filters,
                            isLeadFree: e.target.checked,
                          })
                        }
                        className="rounded border-gray-300 text-green-600 focus:ring-green-500"
                      />
                      <span className="text-sm text-gray-600">Lead Free</span>
                    </label>

                    <label className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        checked={filters.isPTFEFree}
                        onChange={(e) =>
                          handleFilterChange({
                            ...filters,
                            isPTFEFree: e.target.checked,
                          })
                        }
                        className="rounded border-gray-300 text-green-600 focus:ring-green-500"
                      />
                      <span className="text-sm text-gray-600">PTFE Free</span>
                    </label>

                    <label className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        checked={filters.isPhthalateFree}
                        onChange={(e) =>
                          handleFilterChange({
                            ...filters,
                            isPhthalateFree: e.target.checked,
                          })
                        }
                        className="rounded border-gray-300 text-green-600 focus:ring-green-500"
                      />
                      <span className="text-sm text-gray-600">
                        Phthalate Free
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Price Range Button */}
        <div className="relative">
          <button
            onClick={() => toggleDropdown("price")}
            className={`flex items-center gap-2 px-3 py-2 rounded-lg text-sm font-medium transition-all ${
              activeDropdown === "price" || filters.price.length > 0
                ? "bg-green-50 text-green-600"
                : "hover:bg-gray-50 text-gray-700"
            }`}
          >
            Price Range
            <ChevronDown size={16} />
            {filters.price.length > 0 && (
              <span className="bg-green-100 text-green-600 rounded-full px-2 text-xs">
                {filters.price.length}
              </span>
            )}
          </button>

          {activeDropdown === "price" && (
            <div className="absolute top-full left-0 mt-2 w-48 bg-white rounded-lg shadow-lg p-4 z-50">
              <div className="space-y-2">
                {dropdownOptions.price.map((range) => (
                  <label key={range.label} className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      checked={filters.price.some(
                        (f) => f.min === range.min && f.max === range.max
                      )}
                      onChange={(e) => {
                        const newPriceRanges = e.target.checked
                          ? [...filters.price, range]
                          : filters.price.filter(
                              (f) => f.min !== range.min || f.max !== range.max
                            );
                        handleFilterChange({
                          ...filters,
                          price: newPriceRanges,
                        });
                      }}
                      className="rounded border-gray-300 text-green-600 focus:ring-green-500"
                    />
                    <span className="text-sm text-gray-600">{range.label}</span>
                  </label>
                ))}
              </div>
            </div>
          )}
        </div>

        {/* Sort Button */}
        <div className="relative">
          <button
            onClick={() => toggleDropdown("sort")}
            className={`flex items-center gap-2 px-3 py-2 rounded-lg text-sm font-medium transition-all ${
              activeDropdown === "sort" || sortOption
                ? "bg-green-50 text-green-600"
                : "hover:bg-gray-50 text-gray-700"
            }`}
          >
            Sort By
            <ChevronDown size={16} />
          </button>

          {activeDropdown === "sort" && (
            <div className="absolute top-full left-0 mt-2 w-48 bg-white rounded-lg shadow-lg p-4 z-50">
              <div className="space-y-2">
                {dropdownOptions.sort.map((option) => (
                  <label key={option.value} className="flex items-center gap-2">
                    <input
                      type="radio"
                      name="sort"
                      value={option.value}
                      checked={sortOption === option.value}
                      onChange={(e) => {
                        handleSortChange(e.target.value);
                        setActiveDropdown(null);
                      }}
                      className="text-green-600 focus:ring-green-500"
                    />
                    <span className="text-sm text-gray-600">
                      {option.label}
                    </span>
                  </label>
                ))}
              </div>
            </div>
          )}
        </div>

        {defaultFilters?.uses && (
          <button
            onClick={onReset}
            className="ml-auto bg-gradient-to-r from-green-600 to-teal-600 text-white px-4 py-2 rounded-lg text-sm font-medium hover:from-green-700 hover:to-teal-700 transition-all"
          >
            Click and Show All
          </button>
        )}
      </div>
    </div>
  );
};

export default FilterPanel;
