export const dropdownOptions = {
  price: [
    { label: "Under $50", min: 0, max: 50 },
    { label: "$50 - $100", min: 50, max: 100 },
    { label: "$100 - $200", min: 100, max: 200 },
    { label: "Over $200", min: 200, max: Infinity },
  ],
  sort: [
    { value: "price-asc", label: "$ Low to High" },
    { value: "price-desc", label: "$ High to Low" },
    // { value: "rating-desc", label: "Top Rated" },
    { value: "capacity-desc", label: "Largest" },
    { value: "power-desc", label: "Most Powerful" },
  ],
};

export const certificationss = [
  {
    type: "bpa free",
    description:
      "BPA-free materials ensure no harmful chemicals leach into your beverages, while additional safety certifications provide extra peace of mind.",
  },
  {
    type: "nsf certified",
    description:
      "The National Sanitation Foundation Institute is a sanitation and safety authority that certifies food equipment that is hygienically designed and built in accordance with U.S. Food and Drug Administration (FDA) food safety and environmental health standards.",
  },
  {
    type: "pvc free",
    description:
      "PVC-free blenders are made with alternative materials like polypropylene, silicone, or stainless steel, reducing exposure to potentially harmful chemicals and contributing to a more eco-friendly kitchen.",
  },
  {
    type: "ptfe free",
    description:
      "PTFE-free blenders ensure a safer, non-toxic blending experience by avoiding components with Teflon coatings that can release harmful fumes when heated.",
  },
  {
    type: "pfoa free",
    description:
      "PFOA-free products minimize exposure to perfluorooctanoic acid, a synthetic chemical linked to various health concerns including certain cancers and thyroid disease.",
  },
  {
    type: "phthalate free",
    description:
      "Phthalate-free products help reduce exposure to plasticizers that have been linked to hormone disruption and developmental issues.",
  },
  {
    type: "lead free",
    description:
      "Lead-free products protect against exposure to this toxic metal that can cause serious health problems, especially in children and adults.",
  },
];

