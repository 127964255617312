import "./App.css";
import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ComparePage from "./pages/ComparePage/ComparePage";
import HomePage from "./pages/Home/HomePage";
import Footer from "./components/Layout/footer";
import Navigation from "./components/Layout/Navigation";
import ReviewPage from "./pages/Reviews/Reviews";
// import Template from "./components/BlenderComparison";
// import AboutUs from "./pages/About";
// import Contact from "./pages/Contact";
import Article from "./pages/Reviews/components/Article";
import RecipesPage from "./pages/Recipe/Recipes";
import ReactGA from "react-ga4";

const App = () => {

  useEffect(() => {
    ReactGA.initialize("G-ZR9J7WVV0S");
    ReactGA.send("pageview");
  }, []);

  return (
    <Router>
      <Navigation />
      <div className="min-h-screen bg-green-50">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/*" element={<HomePage />} />
          <Route path="/compare" element={<ComparePage />} />
          <Route path="/reviews" element={<ReviewPage />} />
          <Route path="/recipes" element={<RecipesPage />} />
          <Route path="/comparisons/:comparisonSlug" element={<Article />} />
        </Routes>
      </div>

      <Footer />
    </Router>
  );
};

export default App;
