import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { Check, X, Star } from "lucide-react";
import CustomMarkdown from "../../../components/Common/CustomMarkdown";

const Article = () => {
  const location = useLocation();
  const { data } = location.state;
  const questions = data.questions;

  return (
    <article className="max-w-4xl mx-auto px-4 py-8">
      <CustomMarkdown
        markdown={data.content}
        img1={data.firstProduct.image}
        img2={data.secondProduct.image}
        item1={data.firstProduct.name}
        item2={data.secondProduct.name}
        url1={data.firstProduct.url}
        url2={data.secondProduct.url}
        isComparison={data.isComparison}
      />
      {/* Specifications Table */}
      <section className="max-w-7xl mx-auto px-4 py-12">
        <h2 className="text-3xl font-bold mb-8">Technical Specifications</h2>
        <div className="overflow-x-auto">
          <table className="w-full bg-white rounded-xl">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-4 text-left text-gray-600">
                  Specification
                </th>
                <th className="px-6 py-4 text-left text-gray-600">
                  {data.firstProduct.name}
                </th>

                {data.isComparison && (
                  <th className="px-6 py-4 text-left text-gray-600">
                    {data.secondProduct.name}
                  </th>
                )}
              </tr>
            </thead>
            <tbody className="divide-y">
              {[
                { label: "Dimensions", key: "dimensions" },
                { label: "Capacity", key: "capacity" },
                { label: "Weight", key: "weight" },
                { label: "Included Components", key: "included" },
                { label: "Warranty", key: "warranty" },
              ].map((row) => (
                <tr key={row.label} className="hover:bg-gray-50">
                  <td className="px-6 py-4 font-medium">{row.label}</td>
                  <td className="px-6 py-4">{data.firstProduct[row.key]}</td>
                  {data.isComparison && (
                    <td className="px-6 py-4">{data.secondProduct[row.key]}</td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>
      {/* Common Questions */}
      <section className="max-w-7xl mx-auto px-4 py-12">
        <h2 className="text-3xl font-bold mb-8">Common Questions Answered</h2>
        <div className="bg-white rounded-xl p-8">
          <div className="space-y-6">
            <div>
              <h3 className="text-xl font-bold mb-3">
                {data.questions[0].question}
              </h3>
              <p className="text-gray-700">{data.questions[0].answer}</p>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-3">
                {data.questions[1].question}
              </h3>
              <p className="text-gray-700">{data.questions[1].answer}</p>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-3">
                {data.questions[2].question}
              </h3>
              <p className="text-gray-700">{data.questions[2].answer}</p>
            </div>
          </div>
        </div>
      </section>
    </article>
  );
};

export default Article;
