import React, { useState } from "react";
import {
  ArrowRight,
  Smile,
  DollarSign,
  Filter,
} from "lucide-react";
import BlenderList from "./components/BlenderList";

const HomePage = () => {
  const [selectedUses, setSelectedUses] = useState([]);
  const [selectedBudgets, setSelectedBudgets] = useState([]);
  const [defaultFilters, setDefaultFilters] = useState(null);

  const toggleUse = (use) => {
    setSelectedUses((prev) =>
      prev.includes(use) ? prev.filter((item) => item !== use) : [...prev, use]
    );
  };

  const toggleBudget = (budget) => {
    setSelectedBudgets((prev) =>
      prev.includes(budget)
        ? prev.filter((item) => item !== budget)
        : [...prev, budget]
    );
  };

  const scrollToElement = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <>
      <div className="relative overflow-hidden">
        <div className="absolute inset-0 opacity-10" />

        <div className="max-w-7xl mx-auto px-4 pt-12 pb-24">
          <div className="text-center relative z-10">
            <span className="bg-green-100 text-green-800 px-4 py-1.5 rounded-full text-sm font-medium inline-block mb-6">
              Find Your Perfect Match
            </span>
            <h2 className="text-5xl font-bold text-gray-900 mb-6 leading-tight">
              Blend Your Way to
              <span className="bg-gradient-to-r from-green-600 to-teal-600 bg-clip-text text-transparent">
                {" "}
                Better Health
              </span>
            </h2>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto mb-10">
              Compare top blenders and find the perfect match for your needs
            </p>

            {/* Quick Match Quiz */}
            <div className="max-w-3xl mx-auto bg-white rounded-2xl shadow-xl p-8 mb-12">
              <h3 className="text-2xl font-bold mb-6">
                Find Your Perfect Blender
              </h3>
              <div className="space-y-6">
                <div>
                  <label className="block text-gray-700 mb-3">
                    What will you blend most often? (Select multiple)
                  </label>
                  <div className="grid grid-cols-2 md:grid-cols-4 gap-3">
                    {[
                      "Smoothies",
                      "Soups",
                      "Baby Food",
                      "Protein Shakes",
                      "Ice Crush",
                      "Frozen",
                      "Milkshake",
                      "Pureeing",
                    ].map((use) => (
                      <button
                        key={use}
                        onClick={() => toggleUse(use)}
                        className={`p-3 rounded-xl border-2 transition-all ${
                          selectedUses.includes(use)
                            ? "border-green-500 bg-green-50 text-green-700"
                            : "border-gray-200 hover:border-green-300"
                        }`}
                      >
                        {use}
                      </button>
                    ))}
                  </div>
                </div>

                <div>
                  <label className="block text-gray-700 mb-3">
                    Your budget range? (Select multiple)
                  </label>
                  <div className="grid grid-cols-2 md:grid-cols-4 gap-3">
                    {["Under $50", "$50-$100", "$100-$200", "$200+"].map(
                      (budget) => (
                        <button
                          key={budget}
                          onClick={() => toggleBudget(budget)}
                          className={`p-3 rounded-xl border-2 transition-all ${
                            selectedBudgets.includes(budget)
                              ? "border-green-500 bg-green-50 text-green-700"
                              : "border-gray-200 hover:border-green-300"
                          }`}
                        >
                          {budget}
                        </button>
                      )
                    )}
                  </div>
                </div>

                <button
                  onClick={() => {
                    setDefaultFilters({
                      uses: selectedUses,
                      budgets: selectedBudgets,
                    });
                    scrollToElement("BlenderList");
                    setSelectedUses([])
                    setSelectedBudgets([])
                  }}
                  className="w-full bg-gradient-to-r from-green-600 to-teal-600 text-white py-4 rounded-xl font-medium hover:from-green-700 hover:to-teal-700 transition-all flex items-center justify-center"
                >
                  Show Best Matches
                  <ArrowRight className="ml-2" size={20} />
                </button>
              </div>
            </div>

            {/* Feature Highlights */}
            <div className="grid md:grid-cols-3 gap-6 mb-16">
              <div className="flex items-center justify-center bg-white rounded-2xl p-6">
                <Smile className="text-green-500 mr-3" size={24} />
                <span className="text-gray-600">Easy Comparison</span>
              </div>
              <div className="flex items-center justify-center bg-white rounded-2xl p-6">
                <Filter className="text-green-500 mr-3" size={24} />
                <span className="text-gray-600">Smart Filters</span>
              </div>
              <div className="flex items-center justify-center bg-white rounded-2xl p-6">
                <DollarSign className="text-green-500 mr-3" size={24} />
                <span className="text-gray-600">Best Prices</span>
              </div>
            </div>

            <BlenderList defaultFilters={defaultFilters} />
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
