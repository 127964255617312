import { useLocation } from "react-router-dom";
import React, { useState, useRef, useMemo } from "react";
import {
  Shield,
  Check,
  ChevronRight,
  ChevronLeft,
  ChevronUp,
  ChevronDown,
} from "lucide-react";
import { formatDimensions, toCamelCase } from "../../utils/string";
import ImageModal from "../../components/Common/ImageModal";
import { certificationss } from "../../constant/constant";
import { useLayout } from "../../hooks/useLayout";
import ReactGA from "react-ga4";

const DescriptionCell = ({ description }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!description) return null;

  const formattedDescription = description.replace(/\\n/g, "<br />");

  return (
    <div className="relative">
      <p
        className="text-sm text-gray-600"
        dangerouslySetInnerHTML={{
          __html: isExpanded
            ? formattedDescription
            : formattedDescription.slice(0, 150) + "...",
        }}
      />
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="text-green-600 text-sm font-medium flex items-center gap-1 mt-2 hover:text-green-700"
      >
        {isExpanded ? (
          <>
            Show Less <ChevronUp size={16} />
          </>
        ) : (
          <>
            Read More <ChevronDown size={16} />
          </>
        )}
      </button>
    </div>
  );
};

// Custom CSS for scrollbar and smooth scrolling
const styles = `
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }
  .scrollbar-hide {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
`;

const ComparisonPage = () => {
  const location = useLocation();
  const { blenders } = location.state;
  const tableRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const { isTablet, isMobile } = useLayout();

  const blenderCount = useMemo(() => {
    return blenders.length;
  }, [blenders.length]);

  const certifications = [
    ...new Set(blenders.map((item) => item.material_type_free).filter(Boolean)),
  ];
  const certificationArray =
    certifications.length > 0
      ? certifications[0]?.split(",").map((item) => item.trim())
      : [];

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedBlender, setSelectedBlender] = useState(null);
  const [initialImageIndex, setInitialImageIndex] = useState(0);

  const handleScroll = (direction) => {
    if (tableRef.current) {
      const scrollAmount = tableRef.current.clientWidth;
      const newScrollPosition =
        direction === "right"
          ? scrollPosition + scrollAmount
          : scrollPosition - scrollAmount;

      tableRef.current.scrollTo({
        left: newScrollPosition,
        behavior: "smooth",
      });
      setScrollPosition(newScrollPosition);
    }
  };

  const handleImageClick = (blender, imageIndex = 0) => {
    setSelectedBlender(blender);
    setInitialImageIndex(imageIndex);
    setModalOpen(true);
  };

  const formatSpecValue = (spec) => {
    return spec !== null && spec !== undefined ? spec : "";
  };

  const isMetricBest = (blender, key) => {
    const best = getBestValue(key);
    if (best === null) return false;

    switch (key) {
      case "motor_watt":
      case "capacity":
        return blender[key] === best;
      case "weight":
      case "price":
        return blender[key] === best;
      default:
        return false;
    }
  };

  const getBestValue = (key) => {
    let best = null;
    switch (key) {
      case "motor_watt":
      case "capacity":
        best = Math.max(...blenders.map((b) => b[key] || 0));
        break;
      case "weight":
      case "price":
        best = Math.min(...blenders.map((b) => b[key] || Infinity));
        break;
      default:
        break;
    }
    return best;
  };

  const getBlenderStrengths = (blender) => {
    const strengths = [];
    if (isMetricBest(blender, "motor_watt")) {
      strengths.push({
        highlight: "Most Powerful",
        description: `With ${blender.motor_watt}W power, it's perfect for tough ingredients and frequent use`,
      });
    }
    if (isMetricBest(blender, "capacity")) {
      strengths.push({
        highlight: "Largest Capacity",
        description: `Its ${blender.capacity}ml capacity makes it ideal for family use and batch preparation`,
      });
    }
    if (isMetricBest(blender, "weight")) {
      strengths.push({
        highlight: "Most Portable",
        description: `At just ${blender.weight}kg, it's perfect for small spaces and on-the-go use`,
      });
    }
    if (isMetricBest(blender, "price")) {
      strengths.push({
        highlight: "Best Value",
        description: `At $${blender.price}, it offers excellent features at a competitive price`,
      });
    }
    return strengths;
  };

  const metrics = [
    {
      section: "Basic Information",
      items: [
        {
          label: "Price",
          key: "price",
          format: (v) => `${v}`,
          best: "min",
          highlight: "Best Value",
        },
        { label: "Brand", key: "brand", format: (v) => toCamelCase(v) },
        {
          label: "Model",
          key: "model_number",
        },
      ],
    },
    {
      section: "Performance & Capacity",
      items: [
        {
          label: "Motor Watt",
          key: "motor_watt",
          format: (v) => {
            if (v) {
              return `${v}W`;
            }
            return "";
          },
          best: "max",
          highlight: "Most Powerful",
        },
        {
          label: "Capacity",
          key: "capacity",
          format: (v) => {
            if (v) {
              return `${v}ml`;
            }
            return "";
          },
          best: "max",
          highlight: "Largest Capacity",
        },
        {
          label: "Speed Settings",
          key: "number_of_speeds",
        },
        {
          label: "Recommended Use",
          key: "recommended_usage",
        },
      ],
    },
    {
      section: "Physical Specifications",
      items: [
        {
          label: "Weight",
          key: "weight",
          format: (v) => {
            if (v) {
              return `${v}kg`;
            }
            return "";
          },
          best: "min",
          highlight: "Most Lightweight",
        },
        {
          label: "Dimensions",
          format: (v) => formatDimensions(v),
          key: "product_dimension",
        },
        { label: "Color", key: "color" },
        {
          label: "Origin",
          key: "country_of_origin",
        },
      ],
    },
    {
      section: "Features & Materials",
      items: [
        {
          label: "Material Type",
          key: "material_type_free",
        },
        {
          label: "Blade Material",
          format: (v) => toCamelCase(v),
          key: "blade_material",
        },
        {
          label: "Power Source",
          key: "power_source",
        },
        {
          label: "Controls",
          key: "controls_type",
        },
        {
          label: "Special Features",
          key: "special_feature",
        },
        blenders.every((blender) => blender?.description !== null) && {
          label: "Description",
          key: "description",
          render: (value) => <DescriptionCell description={value} />,
        },
      ],
    },
  ];

  const mostPowerfulBlender = blenders.reduce((prev, current) =>
    prev.motor_watt > current.motor_watt ? prev : current
  );

  const mostLightWeightBlender = blenders.reduce((prev, current) => {
    if (current.weight === 0) return prev;
    return prev.weight < current.weight ? prev : current;
  });

  const mostCapacitytBlender = blenders.reduce((prev, current) =>
    prev.capacity > current.capacity ? prev : current
  );

  const mostCheaperBlender = blenders.reduce((prev, current) =>
    prev.price < current.price ? prev : current
  );

  return (
    <>
      <style>{styles}</style>
      <div className="container mx-auto py-4 px-4">
        <h2 className="text-2xl font-bold text-center mb-12 mt-12 bg-gradient-to-r from-green-600 to-teal-600 bg-clip-text text-transparent">
          Find Your Perfect Match
        </h2>

        {selectedBlender && (
          <ImageModal
            images={
              selectedBlender.images.split(". ") || [selectedBlender.image]
            }
            isOpen={modalOpen}
            onClose={() => {
              setModalOpen(false);
              setSelectedBlender(null);
            }}
            initialImageIndex={initialImageIndex}
          />
        )}

        {/* Scrollable table container */}
        <div
          ref={tableRef}
          className="bg-white rounded-xl overflow-x-auto scrollbar-hide lg:overflow-visible"
        >
          <table className="w-full">
            <thead>
              <tr>
                <th className="w-60 p-4 left-0 bg-white">
                  {/* You can add a placeholder or label if needed */}
                </th>
                {blenders.map((blender) => (
                  <th key={blender.id} className="w-60 p-4 h-64 min-h-[16rem]">
                    <div className="mx-auto flex flex-col items-center justify-between h-full">
                      <div
                        className="w-40 h-full mb-2 flex items-center justify-center bg-white mx-auto cursor-pointer group relative"
                        onClick={() => handleImageClick(blender)}
                      >
                        <img
                          src={blender.image}
                          alt={blender.name}
                          className="max-h-40 max-w-40 object-contain transition-transform group-hover:scale-105"
                        />
                        {blender.images?.length > 1 && (
                          <div className="absolute top-2 left-2 dark:bg-green-700 dark:text-green-200 text-white text-xs px-2 py-1 rounded-full">
                            {blender.images.split(". ").length} images
                          </div>
                        )}
                      </div>
                      <div className="">
                        <p className="text-[12px] font-semibold text-gray-900 text-center flex items-center justify-center">
                          {toCamelCase(blender.name)}
                        </p>
                      </div>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>

            <tbody>
              {metrics.map((section, sectionIndex) => (
                <React.Fragment key={section.section}>
                  <tr>
                    <td
                      colSpan={blenders.length + 1}
                      className="px-4 py-2 font-semibold text-sm text-gray-700 left-0 bg-white z-20"
                    >
                      {section.section}
                    </td>
                  </tr>

                  {section.items.map(
                    (metric) =>
                      blenders.every(
                        (blender) => blender[metric.key] !== null
                      ) && (
                        <tr
                          key={metric.key}
                          className="border-t hover:bg-gray-50"
                        >
                          <td className="px-4 py-2 left-0 bg-white">
                            <div className="flex items-center gap-2 text-gray-600">
                              {metric.icon}
                              <span className="text-sm">{metric.label}</span>
                            </div>
                          </td>
                          {blenders.map((blender) => (
                            <td key={blender.id} className="px-4 py-2">
                              <div className="flex flex-col items-center">
                                {metric.render ? (
                                  metric.render(blender[metric.key])
                                ) : (
                                  <span
                                    className={`text-sm text-center pt-[10] pb-[10] ${
                                      isMetricBest(blender, metric.key)
                                        ? "font-semibold"
                                        : ""
                                    }`}
                                  >
                                    {metric.format
                                      ? metric.format(blender[metric.key])
                                      : formatSpecValue(blender[metric.key])}
                                  </span>
                                )}
                                {isMetricBest(blender, metric.key) &&
                                  metric.highlight && (
                                    <span className="text-xs text-green-600">
                                      {metric.highlight}
                                    </span>
                                  )}
                              </div>
                            </td>
                          ))}
                        </tr>
                      )
                  )}
                </React.Fragment>
              ))}

              {/* Amazon Buttons Row */}
              <tr className="border-t">
                <td className="px-4 py-4  left-0 bg-white">
                  <div className="flex items-center gap-2 text-gray-600">
                    <span className="text-sm">Purchase</span>
                  </div>
                </td>
                {blenders.map((blender) => (
                  <td
                    key={blender.id}
                    className="px-4 py-4 display text-center"
                  >
                    <a
                      onClick={() => {
                        ReactGA.event({
                          category: "User Interaction",
                          component: "Compare Page",
                          action: "button_click",
                          label: "View on Amazon",
                          params: {
                            asin: blender.amazon_asin,
                          },
                        });
                      }}
                      href={blender.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="inline-flex items-center justify-center px-4 py-2 bg-gradient-to-r from-green-600 to-teal-600 text-white rounded-lg text-sm font-medium hover:from-green-700 hover:to-teal-700 transition-all gap-2"
                    >
                      View on Amazon
                    </a>
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>

        {((blenderCount > 2 && isMobile) || (blenderCount > 3 && isTablet)) && (
          <div className="sticky bottom-8 pt-4 pr-4 w-full flex justify-end z-50 pointer-events-none">
            <div className="flex items-center pointer-events-auto">
              <button
                onClick={() => handleScroll("left")}
                className="
          group transition-all duration-300 
          bg-gradient-to-r from-green-500 to-teal-500 
          hover:from-green-600 hover:to-teal-600 
          text-white 
          p-2
          rounded-full 
          hover:shadow-2xl 
          transform 
          hover:-translate-x-1 
          focus:outline-none 
          focus:ring-2 
          focus:ring-green-300 
          mr-2
        "
              >
                <ChevronLeft
                  className="
            transition-transform 
            duration-300 
            group-hover:scale-110
          "
                  size={24}
                />
              </button>
              <button
                onClick={() => handleScroll("right")}
                className="
          group transition-all duration-300 
          bg-gradient-to-r from-green-500 to-teal-500 
          hover:from-green-600 hover:to-teal-600 
          text-white 
          p-2 
          rounded-full 
          hover:shadow-2xl 
          transform 
          hover:translate-x-1 
          focus:outline-none 
          focus:ring-2 
          focus:ring-green-300 
          ml-2
        "
              >
                <ChevronRight
                  className="
            transition-transform 
            duration-300 
            group-hover:scale-110
          "
                  size={24}
                />
              </button>
            </div>
          </div>
        )}

        <div>
          <h2 className="text-3xl font-bold mb-6 mt-12">
            Which Blender is Right for You?
          </h2>
          <ul className="space-y-4">
            <li className="flex items-start">
              <Check className="pr-1" color="green" />
              <span>
                If you need a <strong>powerful blender</strong> for heavy-duty
                tasks, the{" "}
                <a
                  target="_blank"
                  className="font-bold text-blue-600 hover:text-purple-800 visited:text-blue-700"
                  href={mostPowerfulBlender.url}
                >
                  {toCamelCase(mostPowerfulBlender.brand)} -{" "}
                  {mostPowerfulBlender.model_number}
                </a>{" "}
                with its{" "}
                <strong>
                  {mostPowerfulBlender.motor_watt}
                  -watt
                </strong>{" "}
                motor is the top choice.
              </span>
            </li>
            <li className="flex items-start">
              <Check className="pr-1" color="green" />
              <span>
                For those who want a{" "}
                <strong>lightweight and portable option</strong>, the{" "}
                <a
                  target="_blank"
                  className="font-bold text-blue-600 hover:text-purple-800 visited:text-blue-700"
                  href={mostLightWeightBlender.url}
                >
                  {toCamelCase(mostLightWeightBlender.brand)} -{" "}
                  {mostLightWeightBlender.model_number}
                </a>{" "}
                weighing only{" "}
                <strong>
                  {mostLightWeightBlender.weight}
                  Kg
                </strong>{" "}
                is an excellent pick.
              </span>
            </li>
            <li className="flex items-start">
              <Check className="pr-1" color="green" />
              <span>
                If <strong>capacity</strong> is your main concern, opt for the{" "}
                <a
                  target="_blank"
                  className="font-bold text-blue-600 hover:text-purple-800 visited:text-blue-700"
                  href={mostCapacitytBlender.url}
                >
                  {toCamelCase(mostCapacitytBlender.brand)} -{" "}
                  {mostCapacitytBlender.model_number}
                </a>{" "}
                with its generous{" "}
                <strong>
                  {mostCapacitytBlender.capacity}
                  ml capacity.
                </strong>
              </span>
            </li>
            <li className="flex items-start">
              <Check className="pr-1" color="green" />
              <span>
                When <strong>budget</strong> is a key factor, the{" "}
                <a
                  target="_blank"
                  className="font-bold text-blue-600 hover:text-purple-800 visited:text-blue-700"
                  href={mostCheaperBlender.url}
                >
                  {toCamelCase(mostCheaperBlender.brand)} -{" "}
                  {mostCheaperBlender.model_number}
                </a>{" "}
                offers excellent value at{" "}
                <strong>
                  just {mostCheaperBlender.price}
                  {mostCheaperBlender.price_currency}
                </strong>
                .
              </span>
            </li>
          </ul>
        </div>

        <div className="bg-white rounded-xl  p-6 mt-12 mb-12">
          <div className="space-y-4 col-span-2">
            <h4 className="font-semibold text-green-700 flex items-center gap-2">
              <Shield className="w-5 h-5" />
              For Health-Conscious Users
            </h4>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {blenders
                .filter((b) => b.material_type_free)
                .map((blender) => (
                  <div key={blender.id} className="bg-green-50 rounded-lg p-4">
                    <a
                      target="_blank"
                      className="font-bold text-blue-600 hover:text-purple-800 visited:text-blue-700"
                      href={blender.url}
                    >
                      {toCamelCase(blender.brand)} {blender.model_number}
                    </a>

                    <div className="text-sm text-green-700">
                      Features:
                      {blender.material_type_free
                        .split(",")
                        .map((material, index) => (
                          <span
                            key={index}
                            className="inline-block bg-green-100 text-green-800 rounded-full px-2 py-1 text-xs mr-2 mt-1"
                          >
                            {material.trim()}
                          </span>
                        ))}
                    </div>
                  </div>
                ))}
            </div>
            <p className="text-sm text-gray-600 mt-2">
              These blenders feature materials free from harmful chemicals,
              making them ideal for health-conscious users and families.{" "}
              <br></br>{" "}
              {certificationArray.map((item) => {
                const certification = certificationss.find(
                  (cert) => cert.type === item.toLowerCase()
                );

                return certification ? (
                  <p key={item} className="text-gray-700">
                    <b>{item}:</b> {certification.description}
                  </p>
                ) : null;
              })}
            </p>
          </div>
          <h4 className="font-semibold mb-4 pt-4">
            Individual Blender Highlights
          </h4>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {blenders.map((blender) => {
              const strengths = getBlenderStrengths(blender);
              if (strengths.length === 0) return null;

              return (
                <div key={blender.id} className="space-y-3">
                  <h5 className="font-semibold text-gray-900">
                    <a
                      target="_blank"
                      className="font-bold text-blue-600 hover:text-purple-800 visited:text-blue-700"
                      href={blender.url}
                    >
                      {toCamelCase(blender.brand)} {blender.model_number}
                    </a>
                  </h5>
                  {strengths.map((strength, index) => (
                    <div key={index} className="flex items-start gap-2">
                      <Check
                        className="text-green-500 mt-1 flex-shrink-0"
                        size={16}
                      />
                      <div>
                        <span className="font-medium text-green-600">
                          {strength.highlight}:
                        </span>
                        <span className="text-sm text-gray-600 ml-1">
                          {strength.description}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ComparisonPage;
