import React, { useEffect, useState } from "react";
import { ArrowRight } from "lucide-react";
import getArticles from "../../../api/getArticles";
import { Link } from "react-router-dom";
import VersusImage from "../../../components/Common/VersusImage";

export const ComparisonArticles = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [articles, setArticles] = useState([]);

  // Initial data fetch
  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const response = await getArticles();
        setArticles(response.data);
      } catch (error) {
        console.error("Failed to fetch articles:", error);
        // Optionally set an error state
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  return (
    <div className="my-16">
      <div className="mb-8">
        <h2 className="text-3xl font-bold text-gray-900 mb-4">
          Head-to-Head Comparisons
        </h2>
        <p className="text-gray-600 max-w-3xl">
          Not sure which blender is right for you? Our detailed comparison
          articles pit popular models against each other to help you make the
          best choice.
        </p>
      </div>

      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
        {articles.map((article) => (
          <div
            key={article.id}
            // href={`/comparisons/${article.slug}`}
            className="bg-white rounded-xl overflow-hidden transition-all group"
          >
            <div className="h-60 w-full relative overflow-hidden">
              <Link
                to={`/comparisons/${article.slug}`}
                state={{
                  data: article,
                }}
                className="cursor-pointer"
              >
                {article.isComparison ? (
                  <VersusImage
                    slug={article.slug}
                    firstImage={article.firstProduct.image}
                    secondImage={article.secondProduct.image}
                  />
                ) : (
                  <img
                    src={article.firstProduct.blogImg}
                    alt={article.title}
                    className="w-full h-full object-contain bg-gray-50 group-hover:scale-105 transition-transform duration-300 cursor-pointer"
                  />
                )}
              </Link>

              {/* <div className="absolute inset-0 bg-gradient-to-t from-black/20 to-transparent" /> */}
            </div>

            <div className="p-6">
              <div className="flex items-center gap-4 text-sm text-gray-500 mb-3">
                <span>January 14, 2025,</span>
                <span>•</span>
                <span>2 mins read</span>
              </div>

              <h3 className="text-xl font-bold text-gray-900 mb-3">
                {article.title}
              </h3>

              <p className="text-gray-600 mb-4">{article.excerpt}</p>
              <Link
                to={`/comparisons/${article.slug}`}
                state={{
                  data: article,
                }}
                onClick={() => window.scrollTo(0, 0)}
              >
                <div className="flex items-center text-green-600 font-medium group-hover:text-green-700">
                  Read Comparison
                  <ArrowRight
                    className="ml-2 group-hover:translate-x-1 transition-transform"
                    size={18}
                  />
                </div>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
