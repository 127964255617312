import React, { useState, useEffect, memo } from "react";
import { Check, Loader2 } from "lucide-react";
import getData from "../../../api/api";
import Pagination from "../../../components/Common/Pagination";
import FilterPanel from "./FilterPanel";
import { Link } from "react-router-dom";
import { toCamelCase } from "../../../utils/string";
import ImageModal from "../../../components/Common/ImageModal";
import ProductModal from "./ProductDetails";
import useBlenderFilters from "../../../hooks/useBlenderFilters";
import { useWindowSize } from "../../../hooks/useWindowSize";
import ReactGA from "react-ga4";

const BlenderList = memo(({ defaultFilters }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedBlenders, setSelectedBlenders] = useState([]);
  const [allBlenders, setAllBlenders] = useState([]);
  const [displayedBlenders, setDisplayedBlenders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({
    price: [],
    capacity: [],
    categories: [], // Add categories array to store selected categories
    brands: [],
    isBPAFree: false,
    isPVCFree: false,
    isPFOAFree: false,
    isLeadFree: false,
    isPTFEFree: false,
    isPhthalateFree: false,
    isDishwasherSafe: false,
  });
  const [availableBrands, setAvailableBrands] = useState([]);
  const [sortOption, setSortOption] = useState("");
  const [currentUses, setCurrentUses] = useState([]);

  const ITEMS_PER_PAGE = 9;

  const filteredBlenders = useBlenderFilters(
    allBlenders,
    filters,
    sortOption,
    currentUses
  );
  const windowWidth = useWindowSize();

  // Modal state
  const [modalOpen, setModalOpen] = useState(false);
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [selectedBlender, setSelectedBlender] = useState(null);
  const [initialImageIndex, setInitialImageIndex] = useState(0);

  const handleImageClick = (blender, imageIndex = 0) => {
    setSelectedBlender(blender);
    setInitialImageIndex(imageIndex);
    setModalOpen(true);
  };

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const response = await getData();
        setAllBlenders(response.data);

        // setFilteredBlenders(response.data); // Bu satırı kaldırın

        const brands = [
          ...new Set(response.data.map((blender) => blender.brand)),
        ]
          .filter(Boolean)
          .sort((a, b) => a.localeCompare(b));
        setAvailableBrands(brands);

        setCurrentPage(1); // Sadece sayfa numarasını sıfırlayın
      } catch (error) {
        console.error("Failed to fetch blenders:", error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  // Handle default filters
  useEffect(() => {
    if (defaultFilters) {
      const { uses, budgets } = defaultFilters;

      // Set current uses
      if (uses?.length > 0) {
        setCurrentUses(uses);
      }

      // Set price ranges
      if (budgets?.length > 0) {
        const priceRanges = budgets
          .map((budget) => {
            switch (budget) {
              case "Under $50":
                return { min: 0, max: 50, label: budget };
              case "$50-$100":
                return { min: 50, max: 100, label: budget };
              case "$100-$200":
                return { min: 100, max: 200, label: budget };
              case "$200+":
                return { min: 200, max: Infinity, label: budget };
              default:
                return null;
            }
          })
          .filter((range) => range !== null);

        setFilters((prev) => ({
          ...prev,
          price: priceRanges,
        }));
      }
    }
  }, [defaultFilters]);

  // Apply filters when filters or uses change
  useEffect(() => {
    if (allBlenders.length > 0) {
      // applyFiltersAndSort(allBlenders, currentUses);
    }
  }, [filters, sortOption, currentUses, allBlenders]);

  // Update displayed blenders when filtered results change
  useEffect(() => {
    updateDisplayedBlenders(filteredBlenders, currentPage);
  }, [currentPage, filteredBlenders]);

  const matchesUsage = (blender, selectedUses) => {
    if (!selectedUses || selectedUses.length === 0) return true;
    if (!blender.recommended_usage) return false;

    const blenderUses = blender.recommended_usage
      .toLowerCase()
      .split(",")
      .map((use) => use.trim());
    blenderUses.push(blender.description);

    return selectedUses.every((selectedUse) => {
      const normalizedSelectedUse = selectedUse.toLowerCase();
      return blenderUses.some((blenderUse) => {
        switch (normalizedSelectedUse) {
          case "smoothies":
            return (
              blenderUse.includes("blend") || blenderUse.includes("smoothie")
            );
          case "soups":
            return blenderUse.includes("soup");
          case "baby food":
            return blenderUse.includes("puree") || blenderUse.includes("baby");
          case "protein shakes":
            return (
              blenderUse.includes("shake") ||
              blenderUse.includes("mix") ||
              blenderUse.includes("blend")
            );
          case "ice crush":
            return blenderUse.includes("crush") || blenderUse.includes("ice");
          case "milkshake":
            return (
              blenderUse.includes("shake") ||
              blenderUse.includes("milkshake") ||
              blenderUse.includes("frozen")
            );
          case "frozen":
            return (
              blenderUse.includes("cream") || blenderUse.includes("frozen")
            );
          case "pureeing":
            return blenderUse.includes("puree") || blenderUse.includes("blend");
          default:
            return false;
        }
      });
    });
  };

  const matchesCategory = (blender, selectedCategories) => {
    if (!selectedCategories || selectedCategories.length === 0) return true;
    if (!Array.isArray(blender.category?.categories)) return false;

    // For parent category 'hand', check for its subcategories
    const blenderCats = blender.category?.categories.map((cat) =>
      cat.toLowerCase()
    );

    return selectedCategories.some((selectedCat) => {
      return blenderCats.includes(selectedCat.toLowerCase());
    });
  };

  const updateDisplayedBlenders = (blenders, page) => {
    const startIndex = (page - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    setDisplayedBlenders(blenders.slice(startIndex, endIndex));
  };

  // displayedBlenders'ı filteredBlenders'dan hesapla
  useEffect(() => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    setDisplayedBlenders(filteredBlenders.slice(startIndex, endIndex));
  }, [currentPage, filteredBlenders]);

  const toggleBlenderSelection = (blenderId) => {
    setSelectedBlenders((prev) =>
      prev.includes(blenderId)
        ? prev.filter((id) => id !== blenderId)
        : prev.length < 4
        ? [...prev, blenderId]
        : prev
    );
  };

  const resetFiltersAndSort = () => {
    setFilters({
      price: [],
      capacity: [],
      categories: [],
      brands: [],
      isBPAFree: false,
      isDishwasherSafe: false,
    });
    setSortOption("");
    setCurrentUses([]);
    setCurrentPage(1);
  };

  const BlenderSkeleton = () => (
    <div className="bg-white rounded-xl p-6 animate-pulse">
      <div className="h-6 w-3/4 bg-gray-200 rounded mb-4"></div>
      <div className="h-40 bg-gray-100 rounded mb-4 flex items-center justify-center">
        <Loader2 className="animate-spin text-gray-400" size={32} />
      </div>
      <div className="space-y-3">
        {[1, 2, 3, 4].map((item) => (
          <div key={item} className="h-4 bg-gray-200 rounded w-full"></div>
        ))}
      </div>
      <div className="h-12 bg-gray-100 rounded mt-4"></div>
    </div>
  );

  return (
    <div className="p-8" id="BlenderList">
      <div className="mb-6">
        <h3 className="text-2xl font-bold">Top Blenders</h3>
        <p className="text-sm text-gray-600">
          Select up to 4 blenders to compare
        </p>
      </div>

      {selectedBlender && (
        <ImageModal
          images={selectedBlender.images.split(". ") || [selectedBlender.image]}
          isOpen={modalOpen}
          onClose={() => {
            setModalOpen(false);
            setSelectedBlender(null);
          }}
          initialImageIndex={initialImageIndex}
        />
      )}

      {isLoading ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {[1, 2, 3, 4, 5, 6].map((item) => (
            <BlenderSkeleton key={item} />
          ))}
        </div>
      ) : (
        <>
          <FilterPanel
            filters={filters}
            setFilters={setFilters}
            sortOption={sortOption}
            setSortOption={setSortOption}
            availableBrands={availableBrands}
            // onFilterApply={() => applyFiltersAndSort(allBlenders, currentUses)}
            onReset={resetFiltersAndSort}
            defaultFilters={defaultFilters}
          />

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {displayedBlenders.length > 0 ? (
              displayedBlenders.map((blender) => (
                <div
                  key={blender.id}
                  className={`flex flex-col bg-white rounded-xl p-6 h-full relative transition-all ${
                    selectedBlenders.includes(blender.id)
                      ? "ring-2 ring-green-500"
                      : ""
                  }`}
                >
                  <button
                    onClick={() => toggleBlenderSelection(blender.id)}
                    className={`absolute top-4 right-4 w-6 h-6 rounded-full border-2 flex items-center justify-center ${
                      selectedBlenders.includes(blender.id)
                        ? "bg-green-500 border-green-500 text-white"
                        : "border-gray-300"
                    }`}
                  >
                    {selectedBlenders.includes(blender.id) && (
                      <Check size={14} />
                    )}
                  </button>

                  <div
                    className="mb-4 mt-6 h-40 flex items-center justify-center cursor-pointer group"
                    onClick={() => handleImageClick(blender)}
                  >
                    <img
                      src={blender.image}
                      alt={blender.name}
                      loading="lazy"
                      onError={(e) => {
                        e.target.src = "/placeholder.png";
                      }}
                      className="h-40 w-40 object-contain transition-transform group-hover:scale-105"
                    />
                    {blender.images?.length > 1 && (
                      <div className="absolute top-4 left-4 dark:bg-green-700 dark:text-green-200 text-white text-xs px-2 py-1 rounded-full">
                        {blender.images.split(". ").length} images
                      </div>
                    )}
                  </div>

                  <div className="flex-grow flex flex-col">
                    <h6 className="text-sm font-semibold mb-4 ">
                      {toCamelCase(blender.name)}
                    </h6>

                    <div className="space-y-3 flex-grow">
                      {blender.motor_watt && (
                        <div className="flex justify-between text-sm">
                          <span className="text-gray-600">Motor Power</span>
                          <span className="font-medium">
                            {blender.motor_watt}Watt
                          </span>
                        </div>
                      )}

                      {blender.capacity > 0 && (
                        <div className="flex justify-between text-sm">
                          <span className="text-gray-600">Capacity</span>
                          <span className="font-medium">
                            {blender.capacity}ml
                          </span>
                        </div>
                      )}

                      {blender.number_of_speeds && (
                        <div className="flex justify-between text-sm">
                          <span className="text-gray-600">
                            Number of speeds
                          </span>
                          <span className="font-medium">
                            {blender.number_of_speeds}
                          </span>
                        </div>
                      )}

                      {blender.weight > 0 && (
                        <div className="flex justify-between text-sm">
                          <span className="text-gray-600">Weight</span>
                          <span className="font-medium">
                            {blender.weight} Kg
                          </span>
                        </div>
                      )}

                      {blender.price && (
                        <div className="flex justify-between text-sm">
                          <span className="text-gray-600">Price</span>
                          <span className="font-medium">
                            {blender.price} {blender.price_currency}
                          </span>
                        </div>
                      )}
                    </div>

                    {blender.material_type_free && (
                      <div className="flex flex-wrap gap-1 pt-2">
                        {blender.material_type_free
                          .split(",")
                          .map((item, index) => (
                            <span
                              key={index}
                              className="dark:bg-green-700 dark:text-green-200 text-white text-[11px] font-semibold px-2 py-[2px] rounded-full"
                            >
                              {item.trim()}
                            </span>
                          ))}
                      </div>
                    )}
                    {blender.reviews?.length > 0 ? (
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setSelectedBlender(blender);
                          setDetailModalOpen(true);
                          ReactGA.event({
                            category: "User Interaction",
                            component: "BlenderList",
                            action: "button_click",
                            label: "Show Details",
                            params: {
                              asin: blender.amazon_asin,
                            },
                          });
                        }}
                        className="w-full mt-4 bg-gradient-to-r from-green-600 to-teal-600 text-white py-3 rounded-xl font-medium hover:from-green-700 hover:to-teal-700 transition-all text-center"
                      >
                        Show Details
                      </button>
                    ) : (
                      <a
                        onClick={() => {

                          console.log("on clickte")
                          ReactGA.event({
                            category: "User Interaction",
                            component: "BlenderList",
                            action: "button_click",
                            label: "View on Amazon",
                            params: {
                              asin: blender.amazon_asin,
                            },
                          });
                        }}
                        href={blender.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="w-full mt-4 bg-gradient-to-r from-green-600 to-teal-600 text-white py-3 rounded-xl font-medium hover:from-green-700 hover:to-teal-700 transition-all text-center"
                      >
                        View on Amazon
                      </a>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className="col-span-3 text-center py-12">
                <p className="text-gray-500">
                  No blenders match your selected filters.
                </p>
                <button
                  onClick={resetFiltersAndSort}
                  className="mt-4 text-green-600 hover:text-green-700"
                >
                  Reset Filters
                </button>
              </div>
            )}
          </div>

          <Pagination
            currentPage={currentPage}
            pageCount={Math.ceil(filteredBlenders.length / ITEMS_PER_PAGE)}
            onPageChange={setCurrentPage}
          />
        </>
      )}

      {selectedBlenders.length >= 2 && (
        <Link
          to="compare"
          state={{
            blenders: selectedBlenders.map((id) =>
              allBlenders.find((b) => b.id === id)
            ),
          }}
          onClick={() => window.scrollTo(0, 0)}
          className="fixed bottom-4 right-4 bg-gradient-to-r from-green-600 to-teal-600 text-white px-6 py-3 rounded-xl font-medium hover:from-green-700 hover:to-teal-700 transition-all"
        >
          Compare Selected Blenders ({selectedBlenders.length})
        </Link>
      )}

      {selectedBlender && (
        <ProductModal
          isOpen={detailModalOpen}
          onClose={() => {
            setDetailModalOpen(false);
            setSelectedBlender(null);
          }}
          product={selectedBlender}
          reviews={selectedBlender?.reviews?.filter(
            (review) => review.rating >= 3
          )}
        />
      )}
    </div>
  );
});

export default BlenderList;
