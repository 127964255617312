import React from "react";
import ReactMarkdown from "react-markdown";
import { Check, X, ArrowRight } from "lucide-react";

const CustomMarkdown = ({
  markdown,
  img1,
  img2,
  item1,
  item2,
  url1,
  url2,
  isComparison,
}) => {
  // Custom components for markdown elements
  const components = {
    // Headers
    h1: ({ children }) => (
      <h1 className="text-4xl font-bold text-gray-900 mb-6 mt-8">{children}</h1>
    ),
    h2: ({ children }) => {
      // Special styling for verdict section
      if (children === "The Verdict") {
        return (
          <div className="bg-gradient-to-r from-green-600 to-teal-600 text-white p-4 rounded-t-xl mt-12">
            <h2 className="text-2xl font-bold">{children}</h2>
          </div>
        );
      }
      return (
        <h2 className="text-2xl font-bold text-gray-900 mb-6 mt-12 pb-2 border-b border-gray-200">
          {children}
        </h2>
      );
    },
    h3: ({ children }) => {
      const text = children.toString();
      // Special styling for "Perfect For" and "Not Recommended" sections
      if (text.includes("Perfect For:")) {
        return (
          <h3 className="text-xl font-bold text-green-700 mb-3 mt-6">
            {children}
          </h3>
        );
      }
      if (text.includes("Not Recommended For:")) {
        return (
          <h3 className="text-xl font-bold text-red-700 mb-3 mt-6">
            {children}
          </h3>
        );
      }
      // Special styling for product names
      if (text.includes("Vitamix")) {
        return (
          <h3 className="text-xl font-bold text-gray-900 mb-4 mt-6 flex items-center">
            <span className="w-2 h-2 bg-green-500 rounded-full mr-2"></span>
            {children}
          </h3>
        );
      }
      return (
        <h3 className="text-xl font-bold text-gray-900 mb-3 mt-6">
          {children}
        </h3>
      );
    },
    // Paragraphs
    p: ({ children }) => {
      const text = children.toString();
      // Special styling for product comparison intro
      if (text.includes("When it comes to")) {
        return (
          <p className="text-lg text-gray-600 mb-8 leading-relaxed bg-gray-50 p-6 rounded-xl border border-gray-100">
            {children}
          </p>
        );
      }
      // Special styling for quick overview
      if (text.includes("represents the classic design")) {
        return (
          <p className="text-gray-600 mb-6 leading-relaxed bg-white p-6 rounded-xl shadow-sm">
            {children}
          </p>
        );
      }
      return <p className="text-gray-600 mb-4 leading-relaxed">{children}</p>;
    },
    // Lists
    ul: ({ children }) => <ul className="space-y-3 mb-6">{children}</ul>,
    li: ({ children }) => {
      const text = children.toString();

      // Different styling based on content
      if (text.startsWith("Choose the")) {
        return (
          <li className="flex items-center bg-gray-50 p-4 rounded-lg text-gray-700 font-medium">
            <ArrowRight className="text-green-500 mr-2" size={20} />
            {children}
          </li>
        );
      }

      if (text.includes("Not") || text.includes("Limited")) {
        return (
          <li className="flex items-center text-gray-600">
            <X className="text-red-500 mr-2 flex-shrink-0" size={16} />
            {text}
          </li>
        );
      }

      return (
        <li className="flex items-center text-gray-600">
          <Check className="text-green-500 mr-2 flex-shrink-0" size={16} />
          {children}
        </li>
      );
    },
  };

  return (
    <div className="min-h-screen bg-white">
      <div className="max-w-4xl mx-auto px-4 py-12">
        {/* Product Cards */}
        <div
          className={`grid ${
            isComparison ? "md:grid-cols-2" : "md:grid-cols-1"
          }  gap-6 p-8`}
        >
          <div className="bg-white rounded-xl p-6 shadow-sm">
            <img
              src={img1}
              alt={item1}
              className="w-full h-48 object-contain mb-4"
            />

            <a
              target="_blank"
              className="font-bold text-blue-600 hover:text-purple-800 visited:text-blue-700 cursor-pointer"
              href={url1}
            >
              <h3 className="text-xl font-bold mb-4 text-center pt-4">
                {item1}
              </h3>
            </a>
          </div>
          {isComparison && (
            <div className="bg-white rounded-xl p-6 shadow-sm">
              <img
                src={img2}
                alt={item2}
                className="w-full h-48 object-contain mb-4"
              />
              <a
                target="_blank"
                className="font-bold text-blue-600 hover:text-purple-800 visited:text-blue-700 cursor-pointer"
                href={url2}
              >
                <h3 className="text-xl font-bold mb-4 text-center pt-4">
                  {item2}
                </h3>
              </a>
            </div>
          )}
        </div>

        {/* Main Content */}
        <div className="px-8">
          <ReactMarkdown components={components}>{markdown}</ReactMarkdown>
        </div>
      </div>
    </div>
  );
};

export default CustomMarkdown;
