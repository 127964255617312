import React, { useState } from "react";
import { ProductReviewCard } from "./components/ReviewCard";
import { BuyingGuide } from "./components/BuyingGuide";
import { ComparisonArticles } from "./components/ComparisonArticles";
import { blenders } from "./constant";

const ReviewPage = () => {
  const [activeFilter, setActiveFilter] = useState("all");

  return (
    <div className="min-h-screen bg-gradient-to-b from-green-50 to-green-50">
      <div className="bg-gradient-to-r from-green-600 to-teal-600 text-white">
        <div className="max-w-7xl mx-auto px-4 py-16">
          <h1 className="text-4xl md:text-5xl font-bold mb-6 text-center">
            Best Blenders of 2025
          </h1>
          <p className="text-xl text-center max-w-3xl mx-auto text-green-50">
            Expert-tested, comprehensive reviews to help you find the perfect
            blender for your kitchen
          </p>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 py-12">
        <div className="space-y-12">
          {blenders
            .filter(
              (b) => activeFilter === "all" || b.category === activeFilter
            )
            .map((blender) => (
              <ProductReviewCard key={blender.id} blender={blender} />
            ))}
        </div>

        <ComparisonArticles />
        <BuyingGuide />
      </div>
    </div>
  );
};

export default ReviewPage;
