import React from 'react';
import { Zap, Volume2, Clock } from 'lucide-react';

export const BuyingGuide = () => {
  return (
    <div className="mt-16 bg-white rounded-2xl p-8">
      <h2 className="text-3xl font-bold mb-8">Blender Buying Guide</h2>
      <div className="grid md:grid-cols-2 gap-8">
        <div>
          <h3 className="text-xl font-semibold mb-4">What to Consider</h3>
          <ul className="space-y-4">
            <li className="flex items-start">
              <div className="bg-green-100 rounded-full p-2 mr-4 mt-1">
                <Zap className="text-green-600" size={20} />
              </div>
              <div>
                <span className="font-medium text-gray-900">Power Output</span>
                <p className="text-gray-600 mt-1">
                  For smoothies and basic blending, 600W is sufficient. For tough ingredients like frozen fruits 
                  and ice, look for 1000W or higher.
                </p>
              </div>
            </li>
            <li className="flex items-start">
              <div className="bg-blue-100 rounded-full p-2 mr-4 mt-1">
                <Volume2 className="text-blue-600" size={20} />
              </div>
              <div>
                <span className="font-medium text-gray-900">Noise Level</span>
                <p className="text-gray-600 mt-1">
                  Most powerful blenders are loud, but some premium models include noise-dampening features.
                </p>
              </div>
            </li>
            <li className="flex items-start">
              <div className="bg-purple-100 rounded-full p-2 mr-4 mt-1">
                <Clock className="text-purple-600" size={20} />
              </div>
              <div>
                <span className="font-medium text-gray-900">Warranty</span>
                <p className="text-gray-600 mt-1">
                  Premium blenders often come with 5-10 year warranties, while budget models typically offer 1-3 years.
                </p>
              </div>
            </li>
          </ul>
        </div>
        <div>
          <h3 className="text-xl font-semibold mb-4">Smart Tips</h3>
          <div className="bg-gray-50 rounded-xl p-6 space-y-4">
            <p className="text-gray-700">
              <strong>Best Time to Buy:</strong> Major retail events like Black Friday, Prime Day, and holiday 
              sales often feature significant discounts on premium blenders.
            </p>
            <p className="text-gray-700">
              <strong>Container Material:</strong> Glass is durable but heavy, while BPA-free plastic is 
              lighter and more practical for daily use.
            </p>
            <p className="text-gray-700">
              <strong>Counter Space:</strong> Measure your available counter space and cabinet height 
              before purchasing, especially for larger premium models.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};