import { useState, useEffect } from 'react';

// Define breakpoint types

// Breakpoint values (in pixels)
const breakpoints = {
  'xs': 0,    // Mobile (smallest)
  'sm': 640,  // Small mobile
  'md': 768,  // Tablet
  'lg': 1024, // Desktop
  'xl': 1280, // Large desktop
  '2xl': 1536 // Extra large desktop
};

// Hook to manage responsive layout
export const useLayout = () => {
  // State to track current screen size and breakpoint
  const [screenWidth, setScreenWidth] = useState(
    typeof window !== 'undefined' ? window.innerWidth : 1024
  );

  // Determine current breakpoint
  const getCurrentBreakpoint = (width) => {
    const sortedBreakpoints = Object.entries(breakpoints)
      .sort((a, b) => b[1] - a[1]);
    
    const matchedBreakpoint = sortedBreakpoints.find(
      ([_, value]) => width >= value
    );

    return matchedBreakpoint?.[0] || 'lg';
  };

  // Get current breakpoint
  const currentBreakpoint = getCurrentBreakpoint(screenWidth);

  // Check if current view is mobile or tablet
  const isMobile = currentBreakpoint === 'xs' || currentBreakpoint === 'sm';
  const isTablet = currentBreakpoint === 'md';
  const isDesktop = currentBreakpoint === 'lg' || 
                    currentBreakpoint === 'xl' || 
                    currentBreakpoint === '2xl';

  // Effect to update screen width
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {
    screenWidth,
    currentBreakpoint,
    isMobile,
    isTablet,
    isDesktop
  };
};