// hooks/useBlenderFilters.js
import { useMemo } from "react";

const useBlenderFilters = (allBlenders, filters, sortOption, currentUses) => {
  return useMemo(() => {
    // Önce boş array kontrolü
    if (!allBlenders?.length) return [];

    let filtered = [...allBlenders];

    // Brand filtreleme
    if (filters.brands?.length > 0) {
      filtered = filtered.filter((blender) =>
        filters.brands.includes(blender.brand)
      );
    }

    // Kategori filtreleme
    if (filters.categories?.length > 0) {
      filtered = filtered.filter((blender) => {
        if (!Array.isArray(blender.category?.categories)) return false;
        const blenderCats = blender.category.categories.map((cat) =>
          cat.toLowerCase()
        );
        return filters.categories.some((selectedCat) =>
          blenderCats.includes(selectedCat.toLowerCase())
        );
      });
    }

    // Kullanım amacı filtreleme
    if (currentUses?.length > 0) {
      filtered = filtered.filter((blender) => {
        if (!blender.recommended_usage) return false;

        const blenderUses = blender.recommended_usage
          .toLowerCase()
          .split(",")
          .map((use) => use.trim());
        blenderUses.push(blender.description);

        return currentUses.every((selectedUse) => {
          const normalizedSelectedUse = selectedUse.toLowerCase();
          return blenderUses.some((blenderUse) => {
            switch (normalizedSelectedUse) {
              case "smoothies":
                return (
                  blenderUse.includes("blend") ||
                  blenderUse.includes("smoothie")
                );
              case "soups":
                return blenderUse.includes("soup");
              case "baby food":
                return (
                  blenderUse.includes("puree") || blenderUse.includes("baby")
                );
              case "protein shakes":
                return (
                  blenderUse.includes("shake") ||
                  blenderUse.includes("mix") ||
                  blenderUse.includes("blend")
                );
              case "ice crush":
                return (
                  blenderUse.includes("crush") || blenderUse.includes("ice")
                );
              case "milkshake":
                return (
                  blenderUse.includes("shake") ||
                  blenderUse.includes("milkshake") ||
                  blenderUse.includes("frozen")
                );
              case "frozen":
                return (
                  blenderUse.includes("cream") || blenderUse.includes("frozen")
                );
              case "pureeing":
                return (
                  blenderUse.includes("puree") || blenderUse.includes("blend")
                );
              default:
                return false;
            }
          });
        });
      });
    }

    // Fiyat filtreleme
    if (filters.price?.length > 0) {
      filtered = filtered.filter((blender) =>
        filters.price.some(
          (range) => blender.price >= range.min && blender.price <= range.max
        )
      );
    }

    // Kapasite filtreleme
    if (filters.capacity?.length > 0) {
      filtered = filtered.filter((blender) =>
        filters.capacity.some(
          (range) =>
            blender.capacity >= range.min && blender.capacity <= range.max
        )
      );
    }

    // Materyal filtreleri
    if (filters.isBPAFree) {
      filtered = filtered.filter((blender) =>
        blender.material_type_free?.toLowerCase().includes("bpa free")
      );
    }

    if (filters.isPVCFree) {
      filtered = filtered.filter((blender) =>
        blender.material_type_free?.toLowerCase().includes("pvc free")
      );
    }

    if (filters.isPFOAFree) {
      filtered = filtered.filter((blender) =>
        blender.material_type_free?.toLowerCase().includes("pfoa free")
      );
    }

    if (filters.isLeadFree) {
      filtered = filtered.filter((blender) =>
        blender.material_type_free?.toLowerCase().includes("lead free")
      );
    }

    if (filters.isPTFEFree) {
      filtered = filtered.filter((blender) =>
        blender.material_type_free?.toLowerCase().includes("ptfe free")
      );
    }

    if (filters.isDishwasherSafe) {
      filtered = filtered.filter(
        (blender) => blender.is_diswasher_safe === true
      );
    }

    // Sıralama
    if (sortOption) {
      filtered.sort((a, b) => {
        switch (sortOption) {
          case "price-asc":
            return (a.price || 0) - (b.price || 0);
          case "price-desc":
            return (b.price || 0) - (a.price || 0);
          case "capacity-desc":
            return (b.capacity || 0) - (a.capacity || 0);
          default:
            return 0;
        }
      });
    }

    return filtered;
  }, [allBlenders, filters, sortOption, currentUses]);
};

export default useBlenderFilters;
