export const blenders = [
  {
    id: 1,
    name: "Vitamix Professional Series 750",
    url: "https://www.amazon.com/dp/B00LFVV8CM",
    image: "https://m.media-amazon.com/images/I/71uquUBYHML._AC_SX679_.jpg",
    category: "premium",
    bestFor: ["Smoothies", "Hot Soups", "Nut Butters"],
    power: 1440,
    pros: [
      "Multiple uses: Blending, Pulse, Chopping, Juice, Coffee",
      "5 pre-programmed settings for different recipes",
      "BPA-free container",
      "Family-sized capacity",
      "Aircraft-grade stainless steel blades",
      "30-60 second self-cleaning feature",
    ],
    cons: [
      "High-end features come with premium pricing",
      "Multiple speed settings require practice",
      "64 oz container size isn't ideal for individual servings",
    ],
  },
];
