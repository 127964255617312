import { PinterestIcon } from "./Navigation";
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();

  const scrollToComparisons = () => {
    navigate('/');
    setTimeout(() => {
      const element = document.getElementById('BlenderList');
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 100);
  };

  return (
    <footer className="bg-white border-t">
      <div className="max-w-7xl mx-auto px-8 py-6">
        {/* Main Footer Content */}
        <div className="flex flex-col md:flex-row justify-between gap-8 mb-8">
          {/* Brand Column - Left Side */}
          <div className="space-y-4 md:w-1/3">
            <a
              href="/"
              className="text-xl font-bold bg-gradient-to-r from-green-600 to-teal-600 bg-clip-text text-transparent"
            >
              BestBlenderChoice
            </a>
            <p className="text-gray-600 text-sm">
              Helping you find the perfect blender for your kitchen and
              lifestyle.
            </p>
            <div className="flex items-center space-x-4">
              <a
                href="https://pinterest.com/bestblenderchoice"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-600 hover:text-green-600 transition-colors"
                aria-label="Follow us on Pinterest"
              >
                <PinterestIcon />
              </a>
            </div>
          </div>

          {/* Right Side Sections */}
          <div className="flex flex-col md:flex-row gap-8 md:w-2/3 md:justify-end">
            {/* Quick Links */}
            <div className="md:w-48">
              <h3 className="font-semibold text-gray-900 mb-4">Quick Links</h3>
              <ul className="space-y-3">
                <li>
                  <a
                    href="/reviews"
                    className="text-gray-600 hover:text-green-600 transition-colors"
                  >
                    Reviews
                  </a>
                </li>
                <li>
                  <a
                    href="/recipes"
                    className="text-gray-600 hover:text-green-600 transition-colors"
                  >
                    Recipes
                  </a>
                </li>
                <li>
                  <button
                    onClick={scrollToComparisons}
                    className="text-gray-600 hover:text-green-600 transition-colors cursor-pointer"
                  >
                    Comparisons
                  </button>
                </li>
              </ul>
            </div>
            

            {/* Popular Brands */}
            <div className="md:w-48">
              <h3 className="font-semibold text-gray-900 mb-4">Popular Brands</h3>
              <ul className="space-y-3">
                <li>
                  <a
                    href="https://www.amazon.com/stores/Vitamix/page/28447088-CA79-486D-B75A-6F173E3E8EC5?ref_=ast_bln&store_ref=bl_ast_dp_brandLogo_sto"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-600 hover:text-green-600 transition-colors"
                  >
                    Vitamix
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.amazon.com/stores/Blendtec/page/DAC4DDBB-D5E0-4E65-A42E-82C43712C64E?ref_=ast_bln&store_ref=bl_ast_dp_brandLogo_sto"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-600 hover:text-green-600 transition-colors"
                  >
                    Blendtec
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.amazon.com/stores/Ninja/page/4D837C13-1334-44EE-AD50-9586EE0F3873?ref_=ast_bln&store_ref=bl_ast_dp_brandLogo_sto"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-600 hover:text-green-600 transition-colors"
                  >
                    Ninja
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="pt-6 border-t">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className="text-gray-600 text-sm">
              © {new Date().getFullYear()} BestBlenderChoice. All rights
              reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;