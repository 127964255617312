const getArticles = async () => {
    try {
      const response = await fetch(
        `https://api.bestblenderchoice.com/api/articles`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer c214291450952b519434778c3ba279e5b78fd185517f45fe51a7f831c3e4c9fb99fb847eb329110ddc85c4ea0ac4e29f05cdc9750d4c5eeecc3cf4d2ab8758f66e6b3114998bbd717559d339d24b543874ced14a3af0e0b78d5c9d0755b469caad528cc6222bbfe4b739f85172516a0950b30675816a04c33d2f285000e0062a",
          },
        }
      );
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("error: ", error);
      throw error;
    }
  };
  
  export default getArticles;
  