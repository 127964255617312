import React from "react";
import { Check } from "lucide-react";
import ReactGA from "react-ga4";

export const ProductReviewCard = ({ blender }) => {
  return (
    <div className="bg-white rounded-2xl overflow-hidden">
      <div className="p-8">
        <div className="grid md:grid-cols-2 gap-8">
          {/* Left Column: Image & Quick Stats */}
          <div>
            <div className="bg-gradient-to-br from-gray-50 to-green-50 rounded-xl p-8 mb-6">
              <div className="w-full h-64 relative">
                <img
                  src={blender.image}
                  alt={blender.name}
                  className="w-full h-full object-contain mix-blend-multiply"
                />
              </div>
            </div>

            {/* Specifications Section */}
            <div className="bg-white rounded-xl border border-gray-200 overflow-hidden">
              <div className="divide-y divide-gray-200">
                <div className="grid grid-cols-2 px-4 py-3">
                  <span className="text-gray-600">Capacity</span>
                  <span className="text-gray-900 font-medium">
                    64 Fluid Ounces
                  </span>
                </div>

                <div className="grid grid-cols-2 px-4 py-3">
                  <span className="text-gray-600">Dimensions</span>
                  <span className="text-gray-900 font-medium">
                    8.5"D x 8.5"W x 17.5"H
                  </span>
                </div>

                <div className="grid grid-cols-2 px-4 py-3">
                  <span className="text-gray-600">Wattage</span>
                  <span className="text-gray-900 font-medium">1500 watts</span>
                </div>

                <div className="grid grid-cols-2 px-4 py-3">
                  <span className="text-gray-600">Weight</span>
                  <span className="text-gray-900 font-medium">12.5 Pounds</span>
                </div>
              </div>
            </div>
          </div>

          {/* Right Column: Details */}
          <div className="space-y-6">
            <div>
              <h2 className="text-3xl font-bold text-gray-900 mb-2">
                {blender.name}
              </h2>
            </div>

            {/* Best For Tags */}
            <div>
              <h3 className="font-semibold text-gray-900 mb-3">Best For:</h3>
              <div className="flex flex-wrap gap-2">
                {blender.bestFor.map((use, i) => (
                  <span
                    key={i}
                    className="bg-green-100 text-green-800 px-3 py-1 rounded-full text-sm font-medium"
                  >
                    {use}
                  </span>
                ))}
              </div>
            </div>

            {/* Pros & Cons */}
            <div className="grid md:grid-cols-2 gap-4">
              <div>
                <h3 className="font-semibold text-gray-900 mb-3">Pros</h3>
                <ul className="space-y-2">
                  {blender.pros.map((pro, i) => (
                    <li key={i} className="flex items-center text-gray-600">
                      <Check
                        className="text-green-500 mr-2 flex-shrink-0"
                        size={16}
                      />
                      <span>{pro}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <h3 className="font-semibold text-gray-900 mb-3">Cons</h3>
                <ul className="space-y-2">
                  {blender.cons.map((con, i) => (
                    <li key={i} className="flex items-start text-gray-600">
                      <span className="text-red-500 mr-2">•</span>
                      <span>{con}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {/* CTA Button */}
            <a
              href={blender.url}
              onClick={() => {
                ReactGA.event({
                  category: "User Interaction",
                  component: "ReviewCard",
                  action: "button_click",
                  label: "View on Amazon",
                  params: {
                    asin: blender.amazon_asin,
                  },
                });
              }}
              target="_blank"
              rel="noopener noreferrer"
              className="block w-full bg-gradient-to-r from-green-600 to-teal-600 text-white py-4 px-6 rounded-xl font-medium hover:from-green-700 hover:to-teal-700 transition-all text-center"
            >
              View on Amazon
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
