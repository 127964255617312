import React from "react";
import ReactMarkdown from "react-markdown";
import { X } from "lucide-react";

const RecipeModal = ({ recipe, isOpen, onClose }) => {
  if (!isOpen) return null;

  const components = {
    h1: ({ children }) => (
      <h1 className="text-4xl font-bold mb-6 text-gray-800 border-b pb-4">
        {children}
      </h1>
    ),
    h2: ({ children }) => (
      <h2 className="text-2xl font-semibold mb-4 text-gray-700 mt-8">
        {children}
      </h2>
    ),
    ul: ({ children }) => (
      <ul className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">{children}</ul>
    ),
    ol: ({ children }) => (
      <ol className="space-y-4 mb-6 list-decimal pl-4">{children}</ol>
    ),
    li: ({ children, ordered }) => (
      <li className={`${ordered ? "" : "flex items-start gap-3"}`}>
        {!ordered && (
          <span className="w-2 h-2 bg-green-500 rounded-full mt-2 flex-shrink-0" />
        )}
        <span className="text-gray-600">{children}</span>
      </li>
    ),
    p: ({ children }) => (
      <p className="text-gray-600 leading-relaxed mb-4">{children}</p>
    ),
    img: ({ src, alt, title }) => (
      <div className="my-8 flex justify-center">
        <div className="relative w-full max-w-xl aspect-[3/4] overflow-hidden rounded-xl shadow-xl">
          <img
            src={src}
            alt={alt || "Recipe image"}
            title={title}
            className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition-transform duration-300"
            loading="lazy"
            style={{ imageRendering: "high-quality" }}
            onError={(e) => {
              e.target.style.display = "none";
              console.error(`Failed to load image: ${src}`);
            }}
          />
          {title && (
            <div className="absolute bottom-0 w-full bg-gradient-to-t from-black/70 to-transparent text-white p-4 text-sm font-medium">
              {title}
            </div>
          )}
        </div>
      </div>
    ),
  };

  // Modal içeriğine tıklandığında event'in dışarı yayılmasını engelle
  const handleContentClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div
      className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4"
      onClick={onClose}
    >
      <div
        className="bg-white w-full max-w-4xl rounded-2xl max-h-[90vh] overflow-hidden relative"
        onClick={handleContentClick}
      >
        <button
          onClick={onClose}
          className="absolute top-4 right-4 p-2 rounded-full bg-white/90 shadow-md hover:bg-gray-100 transition-colors z-10"
        >
          <X size={20} />
        </button>

        <div className="overflow-y-auto max-h-[90vh] scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent">
          <div className="p-8">
            <div className="prose max-w-none">
              <ReactMarkdown components={components}>
                {recipe.markdown}
              </ReactMarkdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecipeModal;
