import React from "react";

const VersusImage = ({ firstImage, secondImage }) => {
  return (
    <div className="relative w-full h-full grid grid-cols-2 gap-0">
      {/* Left product */}
      <div className="relative h-60 overflow-hidden">
        <img
          src={firstImage}
          alt={firstImage}
          className="absolute inset-0 w-full h-full object-contain"
        />
      </div>

      {/* Right product */}
      <div className="relative h-60 overflow-hidden">
        <img
          src={secondImage}
          alt={secondImage}
          className="absolute inset-0 w-full h-full object-contain"
        />
      </div>

      {/* VS Badge */}
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-20">
        <div className="bg-gray-500 text-white font-bold rounded-full w-12 h-12 flex items-center justify-center shadow-lg">
          VS
        </div>
      </div>

      {/* Gradient overlay */}
      <div className="absolute inset-0 bg-gradient-to-t from-black/20 to-transparent z-10" />
    </div>
  );
};

export default VersusImage;
