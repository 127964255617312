import React, { useState, useEffect, useMemo } from "react";
import { Search, Clock, BookOpen, Apple, Timer } from "lucide-react";

import RecipeModal from "./components/RecipeModal";
import getRecipes from "../../api/getRecipes";

const RecipesPage = () => {
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [selectedTime, setSelectedTime] = useState("all");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRecipe, setSelectedRecipe] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [recipes, setRecipes] = useState([]);
  const [filteredRecipes, setFilteredRecipes] = useState([]);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const response = await getRecipes();
        setRecipes(response.data);
        setFilteredRecipes(response.data);
      } catch (error) {
        console.error("Failed to fetch:", error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    const recipeFilter = recipes.filter(
      (recipe) =>
        (selectedCategory === "all" || recipe.category === selectedCategory) &&
        (selectedTime === "all" ||
          getTimeCategory(recipe.preparationTime) === selectedTime) &&
        recipe.title.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredRecipes(recipeFilter);
  }, [recipes, searchQuery, selectedCategory, selectedTime]);

  const handleRecipeClick = (recipe) => {
    setSelectedRecipe(recipe);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedRecipe(null);
  };

  const categories = [
    { id: "all", name: "All Recipes", icon: BookOpen },
    { id: "smoothies", name: "Smoothies", icon: Apple },
    // { id: "soups", name: "Soups", icon: Coffee },
    // { id: "sauces", name: "Sauces", icon: Carrot },
  ];

  const timeFilters = [
    { id: "all", name: "All Times" },
    { id: "under5", name: "In 5 mins" },
    { id: "under15", name: "5-15 mins" },
    // { id: "under30", name: "15-30 min" },
    // { id: "over30", name: "Over 30 min" },
  ];

  const getTimeCategory = (minutes) => {
    if (minutes <= 5) return "under5";
    if (minutes <= 15) return "under15";
    if (minutes < 30) return "under30";
    return "over30";
  };

  return (
    <div className="min-h-screen">
      {/* Hero Section */}
      <div className="relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-br from-green-100/20 to-teal-100/20" />

        <div className="max-w-7xl mx-auto px-4 pt-16 pb-12 relative z-10">
          <div className="text-center">
            <span className="bg-green-100 text-green-800 px-4 py-1.5 rounded-full text-sm font-medium inline-block mb-6">
              Blend & Create
            </span>
            <h1 className="text-4xl font-bold text-gray-900 mb-6">
              Delicious
              <span className="bg-gradient-to-r from-green-600 to-teal-600 bg-clip-text text-transparent">
                {" "}
                Blender Recipes
              </span>
            </h1>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto mb-12">
              Discover amazing recipes perfectly crafted for your blender
            </p>

            {/* Search Bar */}
            <div className="max-w-2xl mx-auto relative group mb-16">
              <input
                type="text"
                placeholder="Search recipes..."
                className="w-full px-6 py-4 rounded-2xl border-2 border-gray-200 focus:border-green-500 focus:ring-green-500 text-lg transition-all"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <Search
                className="absolute right-6 top-1/2 transform -translate-y-1/2 text-gray-400 group-hover:text-green-500 transition-colors"
                size={24}
              />
            </div>

            {/* Category Pills */}
            <div className="flex flex-wrap justify-center gap-4 mb-8">
              {categories.map((category) => {
                const Icon = category.icon;
                return (
                  <button
                    key={category.id}
                    onClick={() => setSelectedCategory(category.id)}
                    className={`flex items-center px-6 py-3 rounded-full transition-all ${
                      selectedCategory === category.id
                        ? "bg-gradient-to-r from-green-600 to-teal-600 text-white shadow-md"
                        : "bg-white text-gray-600 hover:bg-green-50 hover:text-green-600"
                    } shadow-sm hover:shadow-md`}
                  >
                    <Icon size={18} className="mr-2" />
                    {category.name}
                  </button>
                );
              })}
            </div>

            {/* Time Filters */}
            <div className="flex flex-wrap justify-center gap-4 mb-12">
              {timeFilters.map((filter) => (
                <button
                  key={filter.id}
                  onClick={() => setSelectedTime(filter.id)}
                  className={`flex items-center px-4 py-2 rounded-lg transition-all ${
                    selectedTime === filter.id
                      ? "bg-green-100 text-green-700 font-medium"
                      : "bg-white text-gray-600 hover:bg-green-50"
                  } shadow-sm`}
                >
                  {filter.id !== "all" && <Timer size={16} className="mr-2" />}
                  {filter.name}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Recipe Grid */}
      <div className="max-w-7xl mx-auto px-4 pb-24 rounded-xl ">
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 rounded-xl ">
          {filteredRecipes.map((recipe) => (
            <div key={recipe.id} className="bg-white rounded-xl ">
              <div className="w-full h-80 overflow-hidden rounded-t-xl">
                <img
                  className="object-cover h-full w-full transform hover:scale-105 transition-transform duration-300"
                  src={recipe.imgUrl}
                  alt={recipe.title}
                />
              </div>

              <div className="p-6">
                <h3 className="text-l font-semibold mb-3 group-hover:text-green-600 transition-colors">
                  {recipe.title}
                </h3>

                {/* Ratings and Time */}
                {/* <div className="flex items-center justify-between mb-4">
                  <div className="flex text-xs items-center text-gray-600 text-sm">
                    <Clock size={12} className="mr-1" />
                    {recipe.preparationTime} mins
                  </div>
                </div> */}

                {/* Nutritional Info */}
                <div className="grid grid-cols-4 gap-2 mb-4 p-3 bg-gray-50 rounded-xl">
                  <div className="text-center">
                    <div className="text-sm font-medium text-gray-900">
                      {recipe.calories}
                    </div>
                    <div className="text-xs text-gray-600">cal</div>
                  </div>
                  <div className="text-center">
                    <div className="text-sm font-medium text-gray-900">
                      {recipe.protein}
                    </div>
                    <div className="text-xs text-gray-600">protein</div>
                  </div>
                  <div className="text-center">
                    <div className="text-sm font-medium text-gray-900">
                      {recipe.fat}
                    </div>
                    <div className="text-xs text-gray-600">fat</div>
                  </div>
                  <div className="text-center">
                    <div className="text-sm font-medium text-gray-900">
                      {recipe.carbs}
                    </div>
                    <div className="text-xs text-gray-600">carbs</div>
                  </div>
                </div>

                {/* Dietary Info */}
                <div className="flex flex-wrap gap-2 mb-4">
                  {recipe.dietaryInfo.map((info) => (
                    <span
                      key={info}
                      className="bg-blue-50 text-blue-700 px-2 py-1 rounded-lg text-xs"
                    >
                      {info}
                    </span>
                  ))}
                </div>

                {/* Ingredients Preview */}
                {/* <div className="mb-6">
                  <h4 className="text-sm font-medium text-gray-900 mb-2">
                    Key Ingredients:
                  </h4>
                  <div className="text-sm text-gray-600">
                    {recipe.ingredients.slice(0, 3).join(", ")}
                    {recipe.ingredients.length > 3 && "..."}
                  </div>
                </div> */}

                <button
                  onClick={() => handleRecipeClick(recipe)}
                  className="w-full bg-gradient-to-r from-green-600 to-teal-600 text-white py-3 rounded-xl font-medium hover:from-green-700 hover:to-teal-700 transition-all flex items-center justify-center group"
                >
                  View Full Recipe
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <RecipeModal
        recipe={selectedRecipe}
        isOpen={isModalOpen}
        onClose={closeModal}
      />
    </div>
  );
};

export default RecipesPage;
