import { Menu, X } from 'lucide-react';
import { useState } from 'react';

export const PinterestIcon = () => (
    <svg 
        viewBox="0 0 24 24" 
        width="20" 
        height="20" 
        stroke="#e60023" 
        strokeWidth="2" 
        fill="#e60023" 
        strokeLinecap="round" 
        strokeLinejoin="round"
        className="lucide-icon"
    >
        <path d="M12 0c-6.627 0-12 5.372-12 12 0 5.084 3.163 9.426 7.627 11.174-.105-.949-.2-2.405.042-3.441.218-.937 1.407-5.965 1.407-5.965s-.359-.719-.359-1.782c0-1.668.967-2.914 2.171-2.914 1.023 0 1.518.769 1.518 1.69 0 1.029-.655 2.568-.994 3.995-.283 1.194.599 2.169 1.777 2.169 2.133 0 3.772-2.249 3.772-5.495 0-2.873-2.064-4.882-5.012-4.882-3.414 0-5.418 2.561-5.418 5.207 0 1.031.397 2.138.893 2.738.098.119.112.224.083.345l-.333 1.36c-.053.22-.174.267-.402.161-1.499-.698-2.436-2.889-2.436-4.649 0-3.785 2.75-7.262 7.929-7.262 4.163 0 7.398 2.967 7.398 6.931 0 4.136-2.607 7.464-6.227 7.464-1.216 0-2.359-.631-2.75-1.378l-.748 2.853c-.271 1.043-1.002 2.35-1.492 3.146 1.124.347 2.317.535 3.554.535 6.627 0 12-5.373 12-12 0-6.628-5.373-12-12-12z" 
        fill="#e60023" 
        stroke="none"
    />
</svg>
);

const Navigation = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    return (
        <nav className="bg-white/80 backdrop-blur-md sticky top-0 z-50">
            <div className="max-w-7xl mx-auto px-4 py-4">
                <div className="flex justify-between items-center">
                    <a href="/" className="text-2xl font-bold bg-gradient-to-r from-green-600 to-teal-600 bg-clip-text text-transparent">
                        BestBlenderChoice
                    </a>
                    
                    {/* Desktop Navigation */}
                    <div className="hidden md:flex items-center">
                        <a 
                            href="/reviews" 
                            className="px-4 py-2 text-gray-600 hover:text-green-600 font-medium"
                        >
                            Reviews
                        </a>
                        <a 
                            href="/recipes" 
                            className="px-4 py-2 text-gray-600 hover:text-green-600 font-medium"
                        >
                            Recipes
                        </a>
                        <div className="h-6 w-px bg-gray-200 mx-2"></div>
                        <a 
                            href="https://pinterest.com/bestblenderchoice" 
                            target="_blank" 
                            rel="noopener noreferrer" 
                            className="flex items-center gap-2 px-4 py-2 text-gray-600 hover:text-green-600 font-medium group"
                            aria-label="Follow us on Pinterest"
                        >
                            <span>Follow us</span>
                            <PinterestIcon />
                        </a>
                    </div>

                    {/* Mobile Menu Button */}
                    <button 
                        className="md:hidden text-gray-600 hover:text-green-600"
                        onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                        aria-label={isMobileMenuOpen ? "Close menu" : "Open menu"}
                    >
                        {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
                    </button>
                </div>

                {/* Mobile Navigation */}
                {isMobileMenuOpen && (
                    <div className="md:hidden pt-4 pb-3">
                        <div className="flex flex-col space-y-2">
                            <a 
                                href="/reviews" 
                                className="px-4 py-2 text-gray-600 hover:text-green-600 font-medium rounded-lg hover:bg-gray-50"
                            >
                                Reviews
                            </a>
                            <a 
                                href="/recipes" 
                                className="px-4 py-2 text-gray-600 hover:text-green-600 font-medium rounded-lg hover:bg-gray-50"
                            >
                                Recipes
                            </a>
                            <div className="h-px bg-gray-200 mx-4"></div>
                            <a 
                                href="https://pinterest.com/bestblenderchoice" 
                                target="_blank" 
                                rel="noopener noreferrer" 
                                className="flex items-center gap-2 px-4 py-2 text-gray-600 hover:text-green-600 font-medium rounded-lg hover:bg-gray-50"
                                aria-label="Follow us on Pinterest"
                            >
                                <span>Follow us</span>
                                <PinterestIcon />
                            </a>
                        </div>
                    </div>
                )}
            </div>
        </nav>
    );
};

export default Navigation;