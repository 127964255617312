import React, { useEffect } from "react";

const Pagination = ({ currentPage, pageCount, onPageChange }) => {
  useEffect(() => {
    const blenderList = document.getElementById("BlenderList");
    if (blenderList) {
      blenderList.scrollIntoView({ behavior: "smooth" });
    }
  }, [currentPage]); // currentPage değiştiğinde tetiklenecek

  const getPageNumbers = () => {
    if (pageCount <= 1) return [];

    let pages = [];
    const maxVisiblePages = window.innerWidth < 640 ? 3 : 5; // Responsive max visible pages
    const halfVisible = Math.floor(maxVisiblePages / 2);

    // Always show first page
    pages.push(1);

    let start = Math.max(2, currentPage - halfVisible);
    let end = Math.min(pageCount - 1, currentPage + halfVisible);

    // Adjust start and end to always show same number of pages
    if (currentPage <= halfVisible + 1) {
      end = Math.min(pageCount - 1, maxVisiblePages - 1);
    }
    if (currentPage >= pageCount - halfVisible) {
      start = Math.max(2, pageCount - maxVisiblePages + 1);
    }

    // Add ellipsis after first page
    if (start > 2) {
      pages.push("...");
    }

    // Add middle pages
    for (let i = start; i <= end; i++) {
      pages.push(i);
    }

    // Add ellipsis before last page
    if (end < pageCount - 1) {
      pages.push("...");
    }

    // Always show last page
    if (pageCount !== 1) {
      pages.push(pageCount);
    }

    return pages;
  };

  // If no pages, return null
  if (pageCount <= 1) return null;

  return (
    <div className="mt-8 flex justify-center">
      <div className="inline-flex items-center gap-1 rounded-lg bg-white p-1 md:p-2 shadow-sm">
        {/* Previous Button - Responsive sizing */}
        <button
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className={`
            px-2 py-1 md:px-3 md:py-1.5 
            text-xs md:text-sm 
            rounded-md 
            ${
              currentPage === 1
                ? "text-gray-400 cursor-not-allowed"
                : "text-gray-700 hover:bg-gray-100"
            } 
            transition-colors 
            focus:outline-none
          `}
        >
          Prev
        </button>

        {/* Page Numbers - Responsive layout */}
        <div className="flex items-center gap-0.5 md:gap-1">
          {getPageNumbers().map((page, index) => (
            <button
              key={index}
              onClick={() =>
                typeof page === "number" ? onPageChange(page) : null
              }
              className={`
                min-w-[24px] md:min-w-[32px] 
                px-1 py-1 md:px-2 md:py-1.5 
                text-xs md:text-sm 
                rounded-md 
                ${
                  page === currentPage
                    ? "bg-green-600 text-white"
                    : page === "..."
                    ? "text-gray-500 cursor-default"
                    : "text-gray-700 hover:bg-gray-100"
                } 
                transition-colors 
                focus:outline-none
              `}
              disabled={page === "..."}
            >
              {page}
            </button>
          ))}
        </div>

        {/* Next Button - Responsive sizing */}
        <button
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === pageCount}
          className={`
            px-2 py-1 md:px-3 md:py-1.5 
            text-xs md:text-sm 
            rounded-md 
            ${
              currentPage === pageCount
                ? "text-gray-400 cursor-not-allowed"
                : "text-gray-700 hover:bg-gray-100"
            } 
            transition-colors 
            focus:outline-none
          `}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Pagination;
